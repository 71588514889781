/** Very basic form validation
 *  ------------------------------------------------------------------------------------------------
 *  just checking if there's a value in all required fields.
**/
import NotificationList from './svelte/NotificationList.svelte';


/** Action functions
 *  ------------------------------------------------------------------------------------------------
**/
const validate = (form) => {
  const invalid = Array.from((form.querySelectorAll('.js--required') || [])).find(
    // look for elements that are required but have no value
    (el) => !el.value.trim(),
  );

  // look for unchecked checkboxes
  // this is very simplistic but the only type of checkboxes we have on this site are required
  const emptyChecks = Array.from((form.querySelectorAll('.js--required.js--check-me') || [])).find(
    // look for checkbox elements that are required but not checked
    (el) => !el.checked,
  );

  if (!invalid && !emptyChecks) {
    form.classList.add('js--validate-me--valid');
    form.classList.remove('js--validate-me--invalid');
  } else {
    form.classList.add('js--validate-me--invalid');
    form.classList.remove('js--validate-me--valid');
  }
};

const validateMyParent = (e) => {
  const parent = e.target.closest('.js--validate-me');
  if (parent) {
    validate(parent);
  }
};

const addNotificationToForm = (form, message) => {
  // remove any old content
  const notificationsHolder = form.querySelector('.js--notifications');
  while (notificationsHolder.firstChild) {
    notificationsHolder.removeChild(notificationsHolder.lastChild);
  }
  // add new notifications
  const disposableNotification = new NotificationList({
    target: form.querySelector('.js--notifications'),
    props: {
      notifications: [{ id: 1, message }],
      removeById: () => { disposableNotification.$destroy(); },
    },
    intro: true,
  });
};

const submitFormIfValid = (e) => {
  // console.log('submitting form');
  e.preventDefault();

  const submitButton = e.target.querySelector('button[type="submit"]');

  if (submitButton) {
    // bail is we're already submitting
    if (submitButton.classList.contains('js--loading')) {
      // console.log('bailing early to avoid double submission');
      return;
    }

    // otherwise add loading class
    submitButton.classList.add('js--loading');
  }

  const invalid = Array.from((e.target.querySelectorAll('.js--required') || [])).find(
    // look for elements that are required but have no value
    (el) => !el.value.trim(),
  );

  if (!invalid) {
    // console.log('Submit the form then display a message...');
    // netlify needs forms to submit as url-encoded string.
    // FormData makes multipart submissions which is not what we want...
    // https://docs.netlify.com/forms/setup/#submit-forms-via-ajax
    // so `const formData = new FormData(e.target);` does not work

    // browser support might need some checking for URLSearchParams
    // we just hard submit if it's not present
    if (!('URLSearchParams' in window)) {
      e.target.submit();
      return;
    }
    const formData = new URLSearchParams(new FormData(e.target)).toString();

    fetch(
      e.target.getAttribute('action'),
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData,
      },
    )
      .then(
        (response) => {
          if (response.ok) {
            e.target.reset();
            addNotificationToForm(e.target, e.target.getAttribute('data-success'));
            submitButton.classList.remove('js--loading');
          } else {
            addNotificationToForm(e.target, `There was a problem submitting this form: ${response.status} : ${response.statusText}`);
            submitButton.classList.remove('js--loading');
          }
        },
      )
      .catch(
        (error) => {
          // console.log(error);
          addNotificationToForm(e.target, error.message);
          submitButton.classList.remove('js--loading');
        },
      );
  }
};

/** Initialise listeners
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
  const forms = document.querySelectorAll('.js--validate-me:not(.js--has-validation)');
  // console.log(`adding ${(forms || []).length} new form listener/s`);
  (forms || []).forEach(
    (form) => {
      // makes it safe to run this multiple times as we don't catch ones we've already observed
      form.classList.add('js--has-validation');
      form.addEventListener('submit', submitFormIfValid, false);
      validate(form);
      (form.querySelectorAll('.js--required') || []).forEach(
        (required) => required.addEventListener('input', validateMyParent, false),
      );
    },
  );
};

export default init;
