<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // translation store
  import translationStore from './store--translations';

  // loading state store
  import loadingStore from './store--loading';

  // svelte components
  import ContentSection from './ContentSection.svelte';
  import Header from './Header.svelte';
  import RichText from './RichText.svelte';
  import Cta from './Cta.svelte';
  import AuthErrorsNotificationList from './AuthErrorsNotificationList.svelte';

  // auth is the GoTrue client
  // eslint-disable-next-line import/prefer-default-export
  // export let auth;

  // sign up form elements
  let email;
  let password;
  let name;
  let phone;
  let street;
  let town;
  let postcode;
  let qualification;
  let genderid;
  let ethnicity;
  let orientation;
  let study;
  let graduation;
  let grade;
  let speciality;

  let passwordVisible = false;

  const togglePasswordVisibility = () => {
    passwordVisible = !passwordVisible;
  };

  // the translation key for this component...
  const translationKey = 'membership';

  // set when a new sign up attempt is made - let's the button reflect loading status
  let loadingKey = '';

  let unsubscribeTranslations;
  let translations;
  let unsubscribeAuthReady;
  let authReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  let signedUp;
  let unsubscribeSignedUp;
  let loadingState;
  let unsubscribeLoadingState;
  onMount(
    () => {
      unsubscribeTranslations = translationStore.subscribe(
        (state) => {
          translations = state;
        },
      );

      unsubscribeAuthReady = authObject.authReady.subscribe(
        (state) => {
          authReady = state;
        },
      );

      unsubscribeLoggedIn = authObject.loggedIn.subscribe(
        (state) => {
          loggedIn = state;
        },
      );

      unsubscribeSignedUp = authObject.signedUp.subscribe(
        (state) => {
          signedUp = state;
        },
      );

      unsubscribeLoadingState = loadingStore.subscribe(
        (state) => {
          loadingState = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeTranslations();
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
      unsubscribeSignedUp();
      unsubscribeLoadingState();
    },
  );

  const signUp = () => {
    const missing = [];
    if (!email) {
      missing.push(
        (translations && translations[translationKey].formValidation.email)
        || 'Email is a required field',
      );
    }
    if (!password) {
      missing.push(
        (translations && translations[translationKey].formValidation.password)
        || 'Password is a required field',
      );
    }
    if (!name) {
      missing.push(
        (translations && translations[translationKey].formValidation.name)
        || 'Name is a required field',
      );
    }
    if (!phone) {
      missing.push(
        (translations && translations[translationKey].formValidation.phone)
        || 'Phone is a required field',
      );
    }
    if (!street) {
      missing.push(
        (translations && translations[translationKey].formValidation.street)
        || 'Street is a required field',
      );
    }
    if (!town) {
      missing.push(
        (translations && translations[translationKey].formValidation.town)
        || 'Town is a required field',
      );
    }
    if (!postcode) {
      missing.push(
        (translations && translations[translationKey].formValidation.postcode)
        || 'Postcode is a required field',
      );
    }

    // qualification level is not stored in database
    // but is used to make at least one set of details required
    if (!qualification || qualification === 'unselected') {
      missing.push(
        (translations && translations[translationKey].formValidation.qualification)
        || 'Please select your qualification level',
      );
    }
    if (qualification === 'student') {
      if (!study) {
        missing.push(
          (translations && translations[translationKey].formValidation.study)
        || 'Place of Study is a required field for student members',
        );
      }
      if (!graduation) {
        missing.push(
          (translations && translations[translationKey].formValidation.graduation)
        || 'Year of graduation is a required field for student members',
        );
      }
    }
    if (qualification === 'qualified') {
      if (!grade) {
        missing.push(
          (translations && translations[translationKey].formValidation.grade)
        || 'Grade is a required field for qualified members',
        );
      }
      if (!speciality) {
        missing.push(
          (translations && translations[translationKey].formValidation.speciality)
        || 'Speciality is a required field for qualified members',
        );
      }
    }


    if (
      missing.length > 0
    ) {
      // true replaces old errors instead of stacking
      authObject.addAnError(missing.join('<br>'), true);
    } else {
      loadingKey = `signup--${Date.now()}`;
      authObject.signUp(email, password, {
        name,
        phone,
        street,
        town,
        postcode,
        genderid,
        ethnicity,
        orientation,
        study,
        graduation,
        grade,
        speciality,
      }, loadingKey);
    }
  };


  // scroll to the top after sign up
  let prevSignedUpStatus;
  $: {
    if (signedUp !== prevSignedUpStatus) {
      if (signedUp === true) {
        window.scrollTo(0, 0);
      }

      prevSignedUpStatus = signedUp;
    }
  }
</script>


<!-- LOADING -->
{#if !authReady}
  <ContentSection
    className='MemberSignup__content MemberSignup__content--loading'
  >
    <RichText
      content={translations && translations?.[translationKey]?.loading?.loadingMessage}
    />
  </ContentSection>
{/if}


<!-- LOGGED IN - We have a user object! -->
{#if authReady && loggedIn}
  <ContentSection
    className='MemberSignup__content MemberSignup__content--logged-in'
  >
    <RichText
      content={translations && translations?.[translationKey]?.loggedIn?.loggedInMessage}
      align='left'
    />

    <div
      class="CTA CTA--align-left"
    >
      <div class="Wrap Wrap--readable u-flows">
        <a
          class="CTA__a use-background--foreground use-colour--foreground"
          href="/me/"
        >
          <span class="CTA__text use-colour--background">
            {translations && translations?.[translationKey]?.loggedIn?.accountButton}
          </span>
        </a>
      </div>
    </div>

    <Cta
      content={translations && translations?.[translationKey]?.loggedIn?.logOutButton}
      click={authObject.logOut}
      align='left'
    />
  </ContentSection>
{/if}


<!-- LOGGED OUT and NOT SIGNED UP YET -->
{#if authReady && !loggedIn && !signedUp}
  <ContentSection
    className='MemberSignup__content MemberSignup__content--logged-out'
  >
    <form
      class="form form--with-sections"
      on:submit|preventDefault={signUp}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {'Login Details'}
          </h5>
          <p class="form__section-instructions use-colour--foreground">
            {'Please use a personal email address if you can.'}
          </p>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="email"
            >
              {translations && translations?.[translationKey]?.loggedOut?.emailLabel} *
            </label>
            <input
              bind:value={email}
              type="email"
              name="email"
              id="email"
              required
              class="use-colour--foreground"
            >
          </div>

          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="password"
            >
              {translations && translations?.[translationKey]?.loggedOut?.passwordLabel} *
            </label>
            {#if passwordVisible }
            <input
              bind:value={password}
              type="text"
              name="password"
              id="password"
              required
              class="use-colour--foreground"
            >
            {:else}
            <input
              bind:value={password}
              type="password"
              name="password"
              id="password"
              required
              class="use-colour--foreground"
            >
            {/if}

            <div class="form__input-group__icon use-colour--foreground">
              <button
                class="form__input-group__icon-content"
                on:click={togglePasswordVisibility}
                type="button"
              >
                <div class="SvgWrap">
                  <div class="SvgWrap__padder" style="padding-bottom: 100%;"></div>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" role="img" aria-label="GLADD">
                    {#if passwordVisible }
                    <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                    {:else}
                    <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                    <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                    <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                    {/if}
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {'Required'}
          </h5>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="name"
            >
              {
                (translations && translations[translationKey].formLabels.nameLabel)
                || 'Name *'
              }
            </label>
            <input
              type="text"
              bind:value={name}
              name="name"
              id="name"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="phone"
            >
            {
              (translations && translations[translationKey].formLabels.phoneLabel)
              || 'Phone Number *'
            }
            </label>
            <input
              type="tel"
              bind:value={phone}
              name="phone"
              id="phone"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="street"
            >
              {
                (translations && translations[translationKey].formLabels.streetLabel)
                || 'Street Address *'
              }
            </label>
            <input
              type="text"
              bind:value={street}
              name="street"
              id="street"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="town"
            >
              {
                (translations && translations[translationKey].formLabels.townLabel)
                || 'Town *'
              }
            </label>
            <input
              type="text"
              bind:value={town}
              name="town"
              id="town"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="postcode"
            >
              {
                (translations && translations[translationKey].formLabels.postCodeLabel)
                || 'Post Code *'
              }
            </label>
            <input
              type="text"
              bind:value={postcode}
              name="postcode"
              id="postcode"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {
              (translations && translations[translationKey].formLabels.qualificationsHeader)
              || 'Qualifications'
            }
          </h5>
          <div class="form__input-group--integrated form__input-group--select use-colour--foreground">
            <select
              bind:value={qualification}
              name="qualification"
              id="qualification"
              class="use-colour--foreground"
              required
            >
              <option value="unselected" selected disabled>
                {
                  (translations && translations[translationKey].formLabels.defaultQualificationText)
                  || 'Select your qualification level'
                } *
              </option>
              <option value="student">
                {
                  (translations && translations[translationKey].formLabels.studentQualificationOption)
                  || 'I am a student'
                }
              </option>
              <option value="qualified">
                {
                  (translations && translations[translationKey].formLabels.qualifiedQualificationOption)
                  || 'I am qualified'
                }
              </option>
              <option value="special">
                {
                  (translations && translations[translationKey].formLabels.specialQualificationOption)
                  || 'I would like to sign up as a special or associate member'
                }
              </option>
            </select>
          </div>
        </section>

        {#if qualification === 'student'}
          <section
            class="form__section u-flows"
          >
            <h5 class="form__section-title use-colour--foreground">
              {
                (translations && translations[translationKey].formLabels.studentsHeader)
                || 'For student members'
              }
            </h5>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="study"
              >
                {
                  (translations && translations[translationKey].formLabels.studyLabel)
                  || 'Place of Study'
                }
              </label>
              <input
                type="text"
                bind:value={study}
                name="study"
                id="study"
                required
                class="use-colour--foreground"
              >
            </div>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="graduation"
              >
                {
                  (translations && translations[translationKey].formLabels.graduationLabel)
                  || 'Year of Graduation'
                }
              </label>
              <input
                type="text"
                bind:value={graduation}
                name="graduation"
                id="graduation"
                required
                class="use-colour--foreground"
              >
            </div>
          </section>
        {/if}


        {#if qualification === 'qualified'}
          <section
            class="form__section u-flows"
          >
            <h5 class="form__section-title use-colour--foreground">
              {
                (translations && translations[translationKey].formLabels.qualifiedHeader)
                || 'For qualified members'
              }
            </h5>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="grade"
              >
                {
                  (translations && translations[translationKey].formLabels.gradeLabel)
                  || 'Grade'
                }
              </label>
              <input
                type="text"
                bind:value={grade}
                name="grade"
                id="grade"
                class="use-colour--foreground"
              >
            </div>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="speciality"
              >
                {
                  (translations && translations[translationKey].formLabels.specialityLabel)
                  || 'Speciality'
                }
              </label>
              <input
                type="text"
                bind:value={speciality}
                name="speciality"
                id="speciality"
                class="use-colour--foreground"
              >
            </div>
          </section>
        {/if}

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {
              (translations && translations[translationKey].formLabels.OptionalHeader)
              || 'Optional'
            }
          </h5>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="genderid"
            >
              {
                (translations && translations[translationKey].formLabels.genderLabel)
                || 'Gender Identity'
              }
            </label>
            <input
              type="text"
              bind:value={genderid}
              name="genderid"
              id="genderid"
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="ethnicity"
            >
              {
                (translations && translations[translationKey].formLabels.ethnicityLabel)
                || 'Ethnicity'
              }
            </label>
            <input
              type="text"
              bind:value={ethnicity}
              name="ethnicity"
              id="ethnicity"
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="orientation"
            >
              {
                (translations && translations[translationKey].formLabels.orientationLabel)
                || 'Sexual Orientation'
              }
            </label>
            <input
              type="text"
              bind:value={orientation}
              name="orientation"
              id="orientation"
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <Cta
            content={translations && translations?.[translationKey]?.loggedOut?.signUpButton}
            click={signUp}
            submit={true}
            align='left'
            loading={loadingState.indexOf(loadingKey) !== -1}
          />
        </section>
      </div>
    </form> <!-- .form -->

    <AuthErrorsNotificationList />
  </ContentSection>
{/if}


<!-- LOGGED OUT but just signed up! -->
{#if authReady && !loggedIn && signedUp}
  <ContentSection
    className='MemberSignup__content MemberSignup__content--logged-out'
  >
    <div
      class="u-flows"
    >
      <Header
        header={translations && translations?.[translationKey]?.loggedOut?.signedUpHeader}
        preHeader={translations && translations?.[translationKey]?.loggedOut?.signedUpPreHeader}
      />
      <RichText
        content={translations && translations?.[translationKey]?.loggedOut?.signedUpBody}
      />
    </div>
  </ContentSection>
{/if}
