<script>
  export let header = '';
  export let preHeader = '';
  export let align = 'left';
  export let priority = 'h2';
</script>

{#if (preHeader && preHeader.length > 0) || (header && header.length > 0)}
<header class={`HeaderText use-colour--foreground HeaderText--align-${align}`}>
  <div class="Wrap Wrap--readable">
    {#if preHeader && preHeader.length > 0}
      <p class="HeaderText__pre">
        {preHeader}
      </p>
    {/if}
    {#if header && header.length > 0}
      {#if priority === 'h4'}
        <h4 class="HeaderText__header">
          {header}
        </h4>
      {/if}
      {#if priority === 'h3'}
        <h3 class="HeaderText__header">
          {header}
        </h3>
      {/if}
      {#if priority === 'h2'}
        <h2 class="HeaderText__header">
          {header}
        </h2>
      {/if}
    {/if}
  </div>
</header>
{/if}
