<script>
  import { fade } from 'svelte/transition';

  export let className;
  export let id = null;
</script>

<section
  class={`ContentSection ContentSection--uncollapsed ContentSection--no-title ContentSection--no-back ContentSection--no-unique-background-colour ${className}`}
  in:fade
  id={id || ''}
>
  <div class="ContentSection__content u-flows">
    <slot></slot>
  </div>
</section>
