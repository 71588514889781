/** Updating event details with JS
 *  ------------------------------------------------------------------------------------------------
 *  We use local storage to only update each event once every 10 mins
**/
const updateTheEvent = (event, details, eventId) => {
  // console.log(`updating event: ${eventId}`);
  // console.log({ event, details, eventId });

  const eventDetails = details[eventId].details;

  if (!eventDetails) {
    return;
  }

  // hide if completed
  if (event.classList.contains('js--hide-me-if-completed') && eventDetails.status !== 'live' && eventDetails.status !== 'started') {
    // eslint-disable-next-line no-param-reassign
    event.style.display = 'none';
    return;
  }

  // process event details
  let eventVenue;
  if (eventDetails.online_event) {
    eventVenue = 'Online Event';
  } else {
    eventVenue = `${eventDetails.venue.name || ''}${eventDetails.venue.address.city ? ` • ${eventDetails.venue.address.city}` : ''}`;
  }

  let eventPricePre = '£';
  let eventPricePost = '';
  if (
    !(eventDetails.is_free)
    && !(eventDetails.ticket_availability.minimum_ticket_price.currency === 'GBP')
  ) {
    eventPricePre = '';
    eventPricePost = ` ${eventDetails.ticket_availability.minimum_ticket_price.currency}`;
  }

  let eventPrice;
  if (eventDetails.is_free) {
    eventPrice = 'FREE';
  } else if (
    eventDetails.ticket_availability.minimum_ticket_price.display
    === eventDetails.ticket_availability.maximum_ticket_price.display
  ) {
    eventPrice = `${eventPricePre}${eventDetails.ticket_availability.maximum_ticket_price.major_value}${eventPricePost}`;
  } else {
    eventPrice = `${eventPricePre}${eventDetails.ticket_availability.minimum_ticket_price.major_value}${eventPricePost} - ${eventPricePre}${eventDetails.ticket_availability.maximum_ticket_price.major_value}${eventPricePost}`;
  }

  let unavailableReason;
  if (!eventDetails.ticket_availability) {
    // if we have no availability info we just report price...
    unavailableReason = eventPrice;
  } else if (!eventDetails.ticket_availability.has_available_tickets) {
    unavailableReason = eventDetails.is_free ? 'Finished' : 'Sales Ended';
  } else if (eventDetails.ticket_availability.is_sold_out) {
    unavailableReason = 'Sold Out';
  }
  const isEventAvailable = (
    eventDetails.ticket_availability
    && eventDetails.ticket_availability.has_available_tickets
    && !eventDetails.ticket_availability.is_sold_out
  );

  const eventDateTime = eventDetails.start.formatted;
  const eventName = eventDetails.name.text;
  const eventSummary = eventDetails.summary;
  const eventUrl = eventDetails.url;

  // is it a small or large one?
  if (event.classList.contains('ContentCollection__item')) {
    // small collection item event.
    const dateContainer = event.querySelector('.ContentCollection__item-pre-header');
    if (dateContainer) {
      dateContainer.innerHTML = eventDateTime;
    }

    const nameContainer = event.querySelector('.ContentCollection__item-header');
    if (nameContainer) {
      nameContainer.innerHTML = eventName;
    }

    const locationAndPriceContainer = event.querySelector('.ContentCollection__item-description');
    if (locationAndPriceContainer) {
      locationAndPriceContainer.innerHTML = `${eventVenue} ${isEventAvailable || unavailableReason.length > 0 ? '|' : ''} ${isEventAvailable ? eventPrice : unavailableReason}`;
    }

    if (event.classList.contains('ContentCollection__item--external')) {
      // we want to update the URL in case it's changed (unlikely)
      const anchor = event.querySelector('.ContentCollection__link');
      if (anchor) {
        anchor.href = eventUrl;
      }
    }
  } else {
    // larger event with extra details.

    // details
    const detailWrap = event.querySelector('.Event__details .Wrap');
    if (detailWrap) {
      detailWrap.innerHTML = `
        <p class="HeaderText__pre">
          ${eventDateTime}
        </p>
        <h2 class="HeaderText__header">
          ${eventName}
        </h2>
        <p class="HeaderText__post">
          ${eventVenue}
        </p>
      `;
    }

    // price and unavailable price class
    const priceWrap = event.querySelector('.Event__price .Wrap');
    if (priceWrap && isEventAvailable) {
      event.querySelector('.Event__price').classList.remove('Event__price--not-available');
      priceWrap.innerHTML = `
        <p class="HeaderText__pre">
          ${eventPrice}
        </p>
      `;
    } else if (priceWrap) {
      event.querySelector('.Event__price').classList.add('Event__price--not-available');
      priceWrap.innerHTML = `
        <p class="HeaderText__pre">
          ${eventPrice}
        </p>
        <p class="HeaderText__post">
          ${unavailableReason}
        </p>
      `;
    }

    // summary
    const summaryWrap = event.querySelector('.RichText .Wrap');
    if (summaryWrap) {
      summaryWrap.innerHTML = eventSummary && eventSummary.length > 0 ? `
        <p>
          ${eventSummary.replace('\n', '<br>')}
        </p>
      ` : '';
    }

    // cta
    const ctaAnchor = event.querySelector('.CTA__a');
    if (ctaAnchor && eventUrl) {
      ctaAnchor.href = eventUrl;
    }
  }
};


export const init = () => {
  const storedEventDetailsString = localStorage.getItem('events');
  let storedEventDetails = storedEventDetailsString
    ? JSON.parse(storedEventDetailsString) : {};

  const targets = document.querySelectorAll('.js--update-this-event');

  if (targets.length > 0) {
    targets.forEach((event) => {
      const eventId = event.getAttribute('data-id');

      if (
        storedEventDetails
        && storedEventDetails[eventId]
        && Date.now() - storedEventDetails[eventId].timestamp < 600000
      ) {
        // this event was already in local storage and was updated less than 10 mins ago
        // console.log('got event details from localStorage');
        // console.log(storedEventDetails[eventId]);

        // we still update because the static HTML might be out of date...
        updateTheEvent(event, storedEventDetails, eventId);
      } else {
        // fetch this event details for the first time.

        fetch(
          '/.netlify/functions/get-event-details',
          {
            method: 'POST',
            body: JSON.stringify({ eventId }),
          },
        ).then(
          (res) => res.json(),
        ).then(
          (res) => {
            // console.log('got event details over the network, placing in localstorage');
            // console.log(res);

            // TODO update the event on the site!
            const updatedDetails = {
              ...storedEventDetails,
              [eventId]: {
                timestamp: Date.now(),
                details: res,
              },
            };

            storedEventDetails = updatedDetails;
            localStorage.setItem('events', JSON.stringify(updatedDetails));

            updateTheEvent(event, storedEventDetails, eventId);
          },
        ).catch(
          (err) => {
            console.error(err);
          },
        );
      }
    });
  }
};

export default init;
