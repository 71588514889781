<script>
  import { afterUpdate } from 'svelte';
  import { init as verticalHeaderInit } from '../verticalHeaders';

  export let header = '';
  export let preHeader = '';

  afterUpdate(() => {
    if ((preHeader && preHeader.length > 0) || (header && header.length > 0)) {
      verticalHeaderInit();
    }
  });
</script>

{#if (preHeader && preHeader.length > 0) || (header && header.length > 0)}
<div class="VerticalHeader">
  <header class="HeaderText use-colour--foreground">
    <div class="Wrap Wrap--readable">
      {#if preHeader && preHeader.length > 0}
        <p class="HeaderText__pre">
          <span>
          {preHeader}
          </span>
        </p>
      {/if}
      {#if header && header.length > 0}
      <h1 class="HeaderText__header">
        <span>
        {header}
        </span>
      </h1>
      {/if}
    </div>
  </header>
</div>
{/if}
