<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // translation store
  // import translationStore from './store--translations';

  // the translation key for this component...
  // const translationKey = 'global';

  let unsubscribeTranslations;
  // let translations;
  let unsubscribeAuthReady;
  let authReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  onMount(
    () => {
      // unsubscribeTranslations = translationStore.subscribe(
      //   (state) => {
      //     translations = state;
      //   },
      // );

      unsubscribeAuthReady = authObject.authReady.subscribe(
        (state) => {
          authReady = state;
        },
      );

      unsubscribeLoggedIn = authObject.loggedIn.subscribe(
        (state) => {
          loggedIn = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeTranslations();
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
    },
  );
</script>


<!-- headers for authed/un-authed people -->
{#if
  authReady && loggedIn
}
  <div
    class="CTA CTA--align-left"
  >
    <a
      class="CTA__a use-colour--foreground"
      href="/me/"
    >
      <span class="CTA__text use-colour--foreground">
        {'Manage Membership'}
      </span>
    </a>
  </div>
  <button
    class="MembershipLinks__sub-link use-colour--foreground"
    on:click={authObject.logOut}
  >
    {'or Log Out'}
  </button>
{:else if authReady}
  <div
    class="CTA CTA--align-left"
  >
    <a
      class="CTA__a use-colour--foreground"
      href="/membership/"
    >
      <span class="CTA__text use-colour--foreground">
        {'Become a Member'}
      </span>
    </a>
  </div>
  <a
    class="MembershipLinks__sub-link use-colour--foreground"
    href="/me/"
  >
    {'or Log In'}
  </a>
{/if}
