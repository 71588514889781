/** Is anything loading store
 *  ------------------------------------------------------------------------------------------------
**/
import { writable } from 'svelte/store';

const store = writable([]);

// subscribe to own store to handle side effects...
store.subscribe(
  (state) => {
    const globalLoader = document.querySelector('.LoadingIndicator');
    if (!globalLoader) {
      return;
    }

    if (state.length > 0) {
      globalLoader.classList.add('js--loading');
    } else {
      globalLoader.classList.remove('js--loading');
    }
  },
);

const remove = (id) => {
  store.update(
    (keys) => keys.filter((key) => key !== id),
  );
};

const add = (key) => {
  store.update(
    (existingKeys) => [
      ...existingKeys,
      key,
    ],
  );
};

// we export our own object
// which allows us to control store business logic away from our components
// it also means we can exclude `set` and `update` methods - we never want them called directly
const loadingStore = {
  subscribe: store.subscribe,
  remove,
  add,
};

export default loadingStore;
