/** imports
 *  ------------------------------------------------------------------------------------------------
**/
import { init as formsInit } from './forms';
import { init as genericIntersectionInit } from './generic-intersection';
import { init as backgroundColourIntersectionInit } from './background-colour-intersection';
import { init as navToggleInit } from './nav-toggle';
// import { soModern, soFunctional } from './test';
import { init as authInit } from './auth';
import { init as verticalHeaderInit } from './verticalHeaders';
import { init as eventsInit } from './events';
import GlobalNotificationList from './svelte/GlobalNotificationList.svelte';
// import CounterOne from './svelte/CounterOne.svelte';
// import CounterTwo from './svelte/CounterTwo.svelte';

import ColourToggle from './svelte/ColourToggle.svelte';


/** babel transpiles things...
 *  ------------------------------------------------------------------------------------------------
**/
// console.log(`Oh. ${soModern()}`);
// soFunctional().forEach((l) => console.log(l));


const init = () => {
  /** Init everything
   *  ----------------------------------------------------------------------------------------------
  **/
  // validation listeners for forms
  formsInit();
  // allowing elements to opt in to generic interesection obs by adding .js--wants-interestion
  genericIntersectionInit();

  // mobile nav toggle
  navToggleInit();
  // initialise profile page and any other login/signup elements
  // passed an array of functions we want to run after components are rendered...
  authInit();
  // size up fixed vertical header
  verticalHeaderInit();

  /** fade between background colours but only on pages with unique sections...
   *  ----------------------------------------------------------------------------------------------
  **/
  const backgroundColourSections = document.querySelectorAll(
    '.ContentSection--with-unique-background-colour',
  );
  const backgroundHaver = document.querySelector('.site-main--unique-sections');
  if ((backgroundColourSections.length > 0) && backgroundHaver) {
    backgroundColourIntersectionInit(
      backgroundColourSections,
      document.querySelector('.body-surrogate'),
    );
  }


  /** Add global notification svelte component
   *  ----------------------------------------------------------------------------------------------
   *  a convenient place for us to throw up messages which we need people to see on any given page
  **/
  const globalNotificationHolder = document.querySelector('.js--GlobalNotifications');

  if (globalNotificationHolder) {
    // eslint-disable-next-line no-unused-vars
    const globalNotificationList = new GlobalNotificationList({
      target: globalNotificationHolder,
    });
  }

  /** Load updated event details if they're more than 10 mins old
   *  ----------------------------------------------------------------------------------------------
  **/
  eventsInit();


  /** Initialise colour mode toggles
   *  ----------------------------------------------------------------------------------------------
  **/
  const colourToggle = document.querySelector('.js--colour-mode-toggle');
  if (colourToggle) {
    // eslint-disable-next-line no-unused-vars
    const svelteColourToggle = new ColourToggle({
      target: colourToggle,
    });
  }
};


if (document.readyState === 'loading') { // Loading hasn't finished yet
  // DOMContentLoaded can sometimes fire very late...
  // it fires after all defer scripts are loaded
  // however the readystatechange changes to interactive BEFORE defer scripts fire...
  // document.addEventListener('DOMContentLoaded', init, false);
  document.addEventListener('readystatechange', (event) => {
    if (event.target.readyState === 'interactive') {
      init();
    }
    // event.target.readyState === 'complete' is available and equivelant to DOMContentLoaded
  });
} else { // readystate is already past loading
  init();
}


/** TEMPORARY OR TEST CODE:
 *  ------------------------------------------------------------------------------------------------
**/


/** Initialise two test svelte components
 *  ------------------------------------------------------------------------------------------------
**/
// const sOne = document.querySelector('.js--CounterOne');
// const sTwo = document.querySelector('.js--CounterTwo');

// if (sOne) {
//   // eslint-disable-next-line no-unused-vars
//   const one = new CounterOne({
//     target: sOne,
//   });
// }

// if (sTwo) {
//   // eslint-disable-next-line no-unused-vars
//   const two = new CounterTwo({
//     target: sTwo,
//   });
// }

/** TEMP: colour toggle js for buttons that don't have URLs (just using this for testing)
 *  ------------------------------------------------------------------------------------------------
**/
// const toggleButtons = document.querySelectorAll('.js--colour-switcher');
// const colours = [
//   'red',
//   'yellow',
//   'green',
//   'teal',
//   'blue',
//   'pink',
//   'purple',
// ];
// let currentColour = -1;

// (toggleButtons || []).forEach(
//   (toggle) => {
//     toggle.addEventListener(
//       'click',
//       (e) => {
//         e.preventDefault();
//         const background = document.querySelector('.body-surrogate');
//         currentColour = currentColour === colours.length - 1 ? 0 : currentColour + 1;
//         background.className = background.className.replace(/(colour--){1}\w*\s*/g, '');
//         background.classList.add(
//           `colour--${colours[currentColour]}`,
//         );
//       },
//       false,
//     );
//   },
// );

// const tester = async () => {
//   const fetchEvent = () => fetch(
//     '/',
//     {
//       method: 'GET',
//     },
//   );

//   try {
//     const event = await fetchEvent();

//     if (event) {
//       console.log(null, 'event');
//     } else {
//       throw new Error('Could not fetch event');
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };
// tester();
