import { writable, readable } from 'svelte/store';
import GoTrue from 'gotrue-js';

// global notifications
import notificationStore from './store--notifications';

// global loading
import loadingStore from './store--loading';

// is someone logged in?
const loggedIn = writable(false);
// was there a succesful sign up this session?
const signedUp = writable(false);
// array of error messages
const errorMessages = writable([]);
// current user info
const userInfo = writable({});
// user details from fauna db
const userDetails = writable(null);
// member's goCardless data
const goCardless = writable(null);
// member's mailchimp status
const mailchimp = writable(null);

let auth;
let ready;

const addAnError = (error, overwrite = false) => {
  let message = 'Sorry that didn\'t work.';

  if (error.json) {
    // we have specific messages for some situations
    // otherwise we revert to the ones baked in to gotrue
    // TODO: bring translation functionality in to these


    if (error.json.error === 'invalid_grant') {
      // login - wrong/missing password or email
      message = 'There is no account with that email, or the password is incorrect.';
    } else if (error.json.msg === 'A user with this email address has already been registered') {
      message = 'A GLADD account with this email address has already been registered<br><br>Forgotten your password? You can request a login link from the <a href="/me/">login page</a>.';
    } else if (error.json.msg === 'User not found') {
      message = 'We cannot find an account with that email address.<br><br>You can <a href=/membership/">sign up</a> for a new account, or <a href="/#contact">contact us</a> if you have forgotten the email address associated with your account.';
    } else if (error.json.error_description) {
      // fall back to built in message if possible
      message = error.json.error_description;
    } else if (error.json.msg) {
      // there are multiple formats for errors!
      message = error.json.msg;
    }
  } else if (typeof error === 'string') {
    message = error;
  }

  if (overwrite) {
    errorMessages.update(() => [
      {
        id: Date.now(),
        message,
      },
    ]);
  } else {
    errorMessages.update(
      (existingMessages) => [
        ...existingMessages,
        {
          id: Date.now(),
          message,
        },
      ],
    );
  }
};

const confirmEmail = (token) => {
  const key = `confirm--email--${Date.now()}`;
  loadingStore.add(key);
  auth
    .confirm(token)
    .then((/* response */) => {
      // console.log('Account confirmed!', JSON.stringify({ response }));
      // we strip the hash if this was succesful
      window.history.replaceState(null, null, ' ');
      // throw up a global notification
      notificationStore.addNotification({
        preHeader: 'Welcome!',
        message: 'Your email has succesfully been verified.<br>You can now log in to your GLADD account.',
      });
      loadingStore.remove(key);
    })
    .catch((e) => {
      console.log(e);
      // throw up a global error message
      notificationStore.addNotification({
        preHeader: 'Error',
        message: 'That confirmation code did not work, it may have expired.<br>You can <a href="/membership/">sign up</a> to get a new verification link.',
      });
      loadingStore.remove(key);
    });
};

const recoverAccount = (token) => {
  const key = `recover-account--${Date.now()}`;
  loadingStore.add(key);
  auth
    // the second (true) argument remembers login rather than making this a one screen thing
    .recover(token, true)
    .then((/* response */) => {
      // console.log('Logged in with recovery token!', JSON.stringify({ response }));
      // we strip the hash if this was succesful
      window.history.replaceState(null, null, ' ');
      // throw up a global notification
      notificationStore.addNotification({
        preHeader: 'Logged In!',
        message: 'You have been logged in succesfully.<br><br>If you requested the login link because you have forgotten your password, you can reset it from <a href="/me/">your account page</a>.',
      });
      loadingStore.remove(key);
      loggedIn.set(true);
      userInfo.set(auth.currentUser());
    })
    .catch((e) => {
      console.log(e);
      // throw up a global error message
      notificationStore.addNotification({
        preHeader: 'Error',
        message: 'That login link did not work, it may have expired.<br>You can request another one from the <a href="/me/">login page</a>.',
      });
      loadingStore.remove(key);
    });
};


const updateVerifiedEmail = (token) => {
  const key = `update--email--${Date.now()}`;
  loadingStore.add(key);
  const user = auth.currentUser();
  if (user) {
    user.update(
      { email_change_token: token },
    )
      .then(
        (/* u */) => {
          // we strip the hash if this was succesful
          window.history.replaceState(null, null, ' ');

          notificationStore.addNotification({
            preHeader: 'Email Updated',
            message: 'Your email address was updated, you can now login with your new address.',
          });
          // console.log('succesfully updated user', u);
          loadingStore.remove(key);
        },
      )
      .catch((/* error */) => {
        notificationStore.addNotification({
          preHeader: 'Error',
          message: 'We could not update to your new email address, please try again or <a href="/#contact">contact us</a>',
        });
        // console.log(`Error updating: ${JSON.stringify({ error })}`);
        loadingStore.remove(key);
      });
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your email address you need to be logged in. Please log in and try clicking the link from your emails again.',
    });
    loadingStore.remove(key);
  }
};


const loadUserDetails = async () => {
  // we intentionall don't display global loader here - it would just add visual noise
  const user = auth.currentUser();
  if (user) {
    // we force jwt refresh when we do this - means that roles update properly...
    return user.jwt(true)
      .then(
        () => fetch('/.netlify/functions/get-member-data', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => data),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error fetching member details', error);
        },
      );
  }

  return {};
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const loadMailchimpStatus = async (key) => {
  const user = auth.currentUser();
  if (user) {
    const loadingKey = key || `loadMailchimp--${Date.now()}`;
    loadingStore.add(loadingKey);
    return user.jwt()
      .then(
        () => fetch('/.netlify/functions/get-mailchimp-status', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            loadingStore.remove(loadingKey);
            // console.log('got mailchimp status');
            // console.log(data);
            mailchimp.set(data || false);
          }),
      )
      .catch(
        (error) => {
          loadingStore.remove(loadingKey);
          addAnError(error);
          console.log('Error fetching mailchimp status', error);
        },
      );
  }

  return {};
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const updateMailchimp = async (type, key) => {
  const user = auth.currentUser();
  if (user) {
    const loadingKey = key || `update-mailchimp--${Date.now()}`;
    loadingStore.add(loadingKey);
    return user.jwt()
      .then(
        () => fetch(type === 'sub' ? '/.netlify/functions/update-mailchimp-sub' : '/.netlify/functions/update-mailchimp-unsub', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            loadingStore.remove(loadingKey);
            // console.log('got mailchimp status');
            // console.log(data);
            mailchimp.set(data || false);
            notificationStore.addNotification({
              preHeader: 'Subscription Updated',
              message: 'Your subscription status has been updated.',
            });
          }),
      )
      .catch(
        (error) => {
          loadingStore.remove(loadingKey);
          addAnError(error);
          console.log('Error fetching mailchimp status', error);
          notificationStore.addNotification({
            preHeader: 'Problem Updating Subscription',
            message: 'Your subscription status could not be updated. Please get in touch and we can update it for you!',
          });
        },
      );
  }

  return {};
};

// Expire an account on login
const expireAccount = async (key) => {
  const loadingKey = key || `expire-account--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/expire-membership', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            notificationStore.addNotification({
              preHeader: 'Your membership has expired.',
              message: data,
            });
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          notificationStore.addNotification({
            preHeader: 'Your membership has expired.',
            message: 'Your membership has lapsed, you can start a new Direct Debit to reactivate your membership.',
          });
          console.log('Error cancelling DD mandate', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const loadGoCardless = async (key) => {
  const user = auth.currentUser();
  if (user) {
    const loadingKey = key || `loadGoCardless--${Date.now()}`;
    loadingStore.add(loadingKey);
    return user.jwt()
      .then(
        () => fetch('/.netlify/functions/get-gocardless-data', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            loadingStore.remove(loadingKey);
            // console.log('setting gocardless details');

            // work out expiration date when loaded
            // we use this to display how long is left on inactive DDs
            // and to check whether we need to unlink/lapse an account
            const dataWithExpiration = {
              ...(data || {}),
            };


            if (data.subscription && data.subscription.status === 'cancelled') {
              // we have a cancelled sub - we might need to deactivate this account
              let expirationDate;
              if (data.payments) {
                const lastSuccesfullPayment = (data.payments.payments || []).find(
                  (payment) => payment.status === 'confirmed' || payment.status === 'paid_out',
                );

                console.log('Last Succesful Payment:');
                console.log(lastSuccesfullPayment);

                if (lastSuccesfullPayment) {
                  const lastPaidString = lastSuccesfullPayment.charge_date;
                  const lastPaidStringSplit = (lastPaidString || '1999-01-01').split('-');
                  const lastPaidUTC = Date.UTC(
                    parseInt(lastPaidStringSplit[0], 10),
                    parseInt(lastPaidStringSplit[1], 10) - 1,
                    parseInt(lastPaidStringSplit[2], 10),
                  );
                  expirationDate = lastPaidUTC + (1000 * 3600 * 24 * 365);
                  const expirationDateDate = new Date(expirationDate);
                  const expirationString = expirationDateDate.toUTCString();
                  dataWithExpiration.expiration = {
                    timestamp: expirationDate,
                    display: expirationString,
                  };

                  console.log(`Time until your membership expires: ${expirationDate - Date.now()}ms`);
                }
              }

              if (!expirationDate || Date.now() > expirationDate) {
                // deactivate!
                console.log('Membership has expired - expiring account.');
                expireAccount();
              }
            }

            console.log(dataWithExpiration || {});
            goCardless.set(dataWithExpiration || {});
          }),
      )
      .catch(
        (error) => {
          loadingStore.remove(loadingKey);
          addAnError(error);
          console.log('Error fetching gocardless details', error);
        },
      );
  }

  return {};
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const updateUserDetails = async (updatedDetails, key) => {
  const loadingKey = key || `update-user--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/update-member-data', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ updatedDetails }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              addAnError('Error updating details - missing required field');
              loadingStore.remove(loadingKey);
            } else {
              userDetails.set(data);
              // console.log('updated! Got details back:', data);
              notificationStore.addNotification({
                preHeader: 'Details Updated',
                message: 'Your details have been updated.',
              });
              loadingStore.remove(loadingKey);
            }
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error updating member details', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


const authReady = readable(
  false,
  // this function gets called only when subscribers go from 0 to 1
  (set) => {
    // set up auth if needed
    if (!auth) {
      // console.log('setting up new auth');
      auth = new GoTrue({
        APIUrl: 'https://gladd.netlify.app/.netlify/identity',
        audience: '',
        setCookie: false,
      });
    }

    const hash = window.location.hash.substr(1);

    // if we have to confirm email address
    if (hash.indexOf('confirmation_token=') === 0) {
      // console.log('confirm email with code...', hash.split('confirmation_token=')[1]);
      confirmEmail(hash.split('confirmation_token=')[1]);
    }

    // if this was an account recovery request
    if (hash.indexOf('recovery_token=') === 0) {
      // console.log('account recovery with code...', hash.split('recovery_token=')[1]);
      recoverAccount(hash.split('recovery_token=')[1]);
    }

    // if this was an updated email confirmation
    if (hash.indexOf('email_change_token=') === 0) {
      // console.log('email change with code...', hash.split('email_change_token=')[1]);
      updateVerifiedEmail(hash.split('email_change_token=')[1]);
    }

    // redirected after successful payment setup
    // if this was an updated email confirmation
    if (hash.indexOf('payment_success=') === 0) {
      window.history.replaceState(null, null, ' ');
      notificationStore.addNotification({
        preHeader: 'Thank You!',
        message: 'Thank you for setting up a direct debit payment.<br><br>GoCardless Ltd will appear on your bank statement when payments are taken against this Direct Debit.<br><br>You can manage your direct debit and see details of your next payment date on this page.',
      });
    }

    const user = auth.currentUser();
    if (user) {
      user.jwt(true)
        .then(
          (/* response */) => {
            // console.log('This is a JWT token', response);
            loggedIn.set(true);
            userInfo.set(auth.currentUser());
            set(true);
          },
        )
        .catch(
          (error) => {
            addAnError(error);
            console.log('Error fetching JWT token', error);
            loggedIn.set(false);
            userDetails.set({});
            set(true);
          },
        );
    } else {
      loggedIn.set(false);
      set(true);
    }

    // this returned function is called when subscribers go from 1 to 0
    // we could unset things here?
    return () => {
      //
    };
  },
);

// we need to know when we are ready
authReady.subscribe(
  (state) => {
    ready = state;
  },
);

// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const loadFullDetails = (key) => {
  const loadingKey = key || `login--${Date.now()}`;
  loadingStore.add(loadingKey);
  loadUserDetails()
    .then(
      (userDets) => {
        // console.log('setting user details');
        // console.log(userDets);
        userDetails.set(userDets.fauna);
        userInfo.set(userDets.netlify);
        loadingStore.remove(loadingKey);
        loadMailchimpStatus();
        if (userDets.fauna && userDets.fauna.goCardlessID) {
          // member has a goCardless account
          console.log('member has goCardless account ID - populating');
          loadGoCardless();
        }
      },
    );
};

// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const logIn = (email, password, key) => {
  if (ready) {
    const loadingKey = key || `login--${Date.now()}`;
    loadingStore.add(loadingKey);
    errorMessages.set([]);
    auth
      .login(email, password, true)
      .then((/* response */) => {
        // console.log(`Success! Response: ${JSON.stringify({ response })}`);
        loadingStore.remove(loadingKey);
        loggedIn.set(true);
        userInfo.set(auth.currentUser());
      })
      .catch((error) => {
        addAnError(error);
        console.log(`Failed... Response: ${JSON.stringify({ error })}`);
        loadingStore.remove(loadingKey);
      });
  }
};

const logOut = () => {
  if (ready) {
    const user = auth.currentUser();
    if (user) {
      user.logout()
        .then(
          (/* response */) => {
            // console.log('User logged out', response);
            loggedIn.set(false);
            userInfo.set({});
            userDetails.set({});
          },
        )
        .catch(
          (error) => {
            addAnError(error);
            console.log('Failed to logout user: %o', error);
            throw error;
          },
        );
    }
  }
};

// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const signUp = (email, password, data, key) => {
  if (ready) {
    const loadingKey = key || `signup--${Date.now()}`;
    loadingStore.add(loadingKey);
    errorMessages.set([]);
    if ((password || '').length < 6) {
      addAnError('Please use more than 6 characters in your password.');
      loadingStore.remove(loadingKey);
      return;
    }
    auth
      .signup(email, password, data)
      .then((/* response */) => {
        signedUp.set(true);
        // console.log(`Success, check inbox! Response: ${JSON.stringify({ response })}`);
        loadingStore.remove(loadingKey);
        notificationStore.addNotification({
          preHeader: 'Welcome!',
          message: 'Thank you for signing up for a GLADD account.<br><br>Please click the link in the email we have sent you to login.',
        });
      })
      .catch((error) => {
        addAnError(error);
        console.log(`Failed... Response: ${JSON.stringify({ error })}`);
        loadingStore.remove(loadingKey);
      });
  }
};

const recovery = (email) => {
  const loadingKey = `reset--${Date.now()}`;
  loadingStore.add(loadingKey);
  if (email && ready) {
    errorMessages.set([]);
    auth
      .requestPasswordRecovery(email)
      .then((/* response */) => {
        addAnError('Check your emails!<br><br>We just sent you a link that will log you in to your account.<br>You can reset your password once you are logged in.');
        // console.log('Recovery email sent', { response });
        loadingStore.remove(loadingKey);
      })
      .catch((error) => {
        addAnError(error);
        console.log(`Error sending recovery mail: ${JSON.stringify({ error })}`);
        loadingStore.remove(loadingKey);
      });
  } else if (ready) {
    errorMessages.set([]);
    addAnError('Please enter your email address above and we will email you a login link.');
    loadingStore.remove(loadingKey);
  }
};

const update = (newDetail, email = false, key) => {
  const loadingKey = key || `update-e-or-p--${Date.now()}`;
  loadingStore.add(loadingKey);
  if ((newDetail || '').length < 6 && !email) {
    notificationStore.addNotification({
      preHeader: 'Password Too Short',
      message: 'Please use more than 6 characters in your password.',
    });
    loadingStore.remove(loadingKey);

    return;
  }
  // update email or password
  if (ready) {
    const user = auth.currentUser();
    if (user) {
      user.update(
        { [email ? 'email' : 'password']: newDetail },
      )
        .then(
          (/* u */) => {
            notificationStore.addNotification({
              preHeader: email ? 'Check Your Emails' : 'Password Updated',
              message: email ? 'We need to verify your new email address.<br>Please click the link in the email we have sent you.' : 'Your password was successfully updated.',
            });
            // console.log('succesfully updated user', u);
            loadingStore.remove(loadingKey);
          },
        )
        .catch((error) => {
          addAnError(error);
          console.log(`Error updating: ${JSON.stringify({ error })}`);
          loadingStore.remove(loadingKey);
        });
    }
  }
};

const clearNotificationById = (id) => {
  errorMessages.update(
    (messages) => messages.filter((message) => message.id !== id),
  );
};

// key is optional arg - allows content to monitor the loadingStore to see if it's still loading
const loadForbiddenKnowledge = async (slug, key) => {
  const loadingKey = key || `forbidden--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    return user.jwt()
      .then(
        (response) => fetch(`/.netlify/functions/${slug}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${response}`,
          },
          body: JSON.stringify({ type: 'content' }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            return data.content;
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error fetching members only content', error);
          loadingStore.remove(loadingKey);
        },
      );
  }

  return fetch(`/.netlify/functions/${slug}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${false}`,
    },
    body: JSON.stringify({ type: 'content' }),
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      loadingStore.remove(loadingKey);
      return data.content;
    })
    .catch(
      (error) => {
        addAnError(error);
        console.log('Error fetching members only content', error);
        loadingStore.remove(loadingKey);
      },
    );
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const newMandate = async (amount, desc, key) => {
  const loadingKey = key || `new-mandate--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/create-gocardless-mandate', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({
            subscription: amount,
            subscriptionDesc: desc,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            if (data.redirect_url) {
              window.location = data.redirect_url;
            } else {
              notificationStore.addNotification({
                preHeader: 'There was a problem setting up direct debit payment.',
                message: 'Please contact us and we can help set up a direct debit for you manually.',
              });
            }
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error creating DD mandate', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


const mandateReflow = async () => {
  const loadingKey = `complete-mandate--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/complete-gocardless-mandate', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({
            search: window.location.search,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            if (data.id) {
              window.location = '/me#payment_success=true';
            } else {
              notificationStore.addNotification({
                preHeader: 'There was a problem processing this direct debit payment.',
                message: `Please try again or contact us and we can help set up a direct debit for you manually.<br><br>Error: ${data}`,
              });
            }
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error processing DD mandate', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


// key is optional arg - allows a button to monitor the loadingStore to see if it's still loading
const cancelMandate = async (key) => {
  const loadingKey = key || `cancel-mandate--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/cancel-gocardless-mandate', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            if (data.status && data.status === 'cancelled') {
              notificationStore.addNotification({
                preHeader: 'Your direct debit was cancelled.',
                message: 'It may take a few minutes for the details on this page to update.',
              });
              goCardless.set(false);
            } else {
              notificationStore.addNotification({
                preHeader: 'There was a problem cancelling your direct debit.',
                message: 'Please try again or contact us and we can cancel it for you manually.',
              });
            }
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          notificationStore.addNotification({
            preHeader: 'There was a problem cancelling your direct debit.',
            message: 'Please try again or contact us and we can cancel it for you manually.',
          });
          console.log('Error cancelling DD mandate', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


const updateMandate = async (amount, desc, key) => {
  const loadingKey = key || `update-mandate--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/update-gocardless-mandate', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({
            subscription: amount,
            subscriptionDesc: desc,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            loadingStore.remove(loadingKey);
            if (data.amount && data.amount === parseInt(amount, 10)) {
              notificationStore.addNotification({
                preHeader: 'Your direct debit was updated.',
                message: 'It may take a few minutes for the details on this page to update.',
              });
            } else {
              notificationStore.addNotification({
                preHeader: 'There was a problem updating your direct debit payment.',
                message: 'Please contact us and we can help update your direct debit for you manually.',
              });
            }
          }),
      )
      .catch(
        (error) => {
          addAnError(error);
          console.log('Error updating DD mandate', error);
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In',
      message: 'To update your details you need to be logged in. Please log in and try again.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


/** ADMIN functions
 *  ------------------------------------------------------------------------------------------------
**/
const adminCheckDetails = (id, key) => {
  const loadingKey = key || `admin-check-details--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/admin-get-member', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ netlifyID: id }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error || !data.netlify || !data.fauna) {
              notificationStore.addNotification({
                preHeader: `Could not fetch details for ${id}`,
                message: `We got the following error: ${data.error || 'unknown problem'}`,
              });
              loadingStore.remove(loadingKey);
            } else {
              notificationStore.addNotification({
                preHeader: `Here are the details for ${id}`,
                message: `
                  email: ${data.fauna.email || '[EMPTY]'}<br>
                  name: ${data.fauna.name || '[EMPTY]'}<br>
                  status: ${(data.netlify.app_metadata.roles || []).join(' + ')}
                `,
              });
              loadingStore.remove(loadingKey);
            }
          }),
      )
      .catch(
        (error) => {
          notificationStore.addNotification({
            preHeader: `Could not fetch details for ${id}`,
            message: `We got the following error: ${error.message || 'unknown problem'}`,
          });
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In As Admin',
      message: 'To use this function you must be an admin.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


const adminChangeStatus = (id, role, key) => {
  if (role !== 'active' && role !== 'lapsed') {
    notificationStore.addNotification({
      preHeader: 'Not a valid status',
      message: 'Please select `Active` or `Lapsed` status',
    });

    return;
  }

  const loadingKey = key || `admin-change-status--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();

  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/admin-change-status', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ netlifyID: id, role }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error || !data.netlify) {
              notificationStore.addNotification({
                preHeader: `Could not update status for ${id}`,
                message: `We got the following error: ${data.error || 'unknown problem'}`,
              });
              loadingStore.remove(loadingKey);
            } else {
              notificationStore.addNotification({
                preHeader: `Updated status for ${id}`,
                message: `status: ${(data.netlify.app_metadata.roles || []).join(' + ')}`,
              });
              loadingStore.remove(loadingKey);
            }
          }),
      )
      .catch(
        (error) => {
          notificationStore.addNotification({
            preHeader: `Could not update status for ${id}`,
            message: `We got the following error: ${error.message || 'unknown problem'}`,
          });
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In As Admin',
      message: 'To use this function you must be an admin.',
    });
    loadingStore.remove(loadingKey);
  }
};


const adminCreateMember = (email, details, key) => {
  const loadingKey = key || `admin-create-member--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();

  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/admin-create-member', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ email, details }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error || !data.netlify) {
              notificationStore.addNotification({
                preHeader: 'New Member Sign Up Failed',
                message: `We got the following error: ${data.error || 'unknown problem'}`,
              });
              loadingStore.remove(loadingKey);
              return false;
            }

            notificationStore.addNotification({
              preHeader: 'New member added',
              message: `They have been randomly assigned a password and should use the Forgotten Password functionality to log in for the first time.<br><br>netlifyID:${data.netlify.id}<br><br>email: ${data.netlify.email}<br><br>status: ${(data.netlify.app_metadata.roles || []).join(' + ')}<br><br><strong>PLEASE: refresh this page before you add another member to make sure you don't re-submit any fields!</strong>`,
            });
            loadingStore.remove(loadingKey);
            return true;
          }),
      )
      .catch(
        (error) => {
          notificationStore.addNotification({
            preHeader: 'New Member Sign Up Failed',
            message: `We got the following error: ${error.message || 'unknown problem'}`,
          });
          loadingStore.remove(loadingKey);
          return false;
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In As Admin',
      message: 'To use this function you must be an admin.',
    });
    loadingStore.remove(loadingKey);
  }

  return false;
};

// uploads one member a second...
const adminBulkUpload = (members, key = '', index = 0) => {
  loadingStore.add(key);
  if (members[index] && members[index].email) {
    adminCreateMember(members[index].email, members[index]);
    window.setTimeout(() => {
      adminBulkUpload(members, key, index + 1);
    }, 1000);
  } else {
    console.log('End of file or missing email!');
    notificationStore.addNotification({
      preHeader: 'End of file or missing email!',
      message: `The index was: ${index}`,
    });
    loadingStore.remove(key);
  }
};


const adminDeleteMember = (id, key) => {
  const loadingKey = key || `admin-delete-member--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/admin-delete-member', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ netlifyID: id }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error || !data.netlify || !data.fauna) {
              notificationStore.addNotification({
                preHeader: `Could not delete details for ${id}`,
                message: `We got the following error: ${data.error || 'unknown problem'}`,
              });
              loadingStore.remove(loadingKey);
            } else {
              notificationStore.addNotification({
                preHeader: `Member was deleted succesfully. deleted: ${id}`,
                message: `Deleted successfully from Netlify and Fauna - previous email address was ${data.fauna}`,
              });
              loadingStore.remove(loadingKey);
            }
          }),
      )
      .catch(
        (error) => {
          notificationStore.addNotification({
            preHeader: `Could not delete details for ${id}`,
            message: `We got the following error: ${error.message || 'unknown problem'}`,
          });
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In As Admin',
      message: 'To use this function you must be an admin.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};


const adminDownloadDatabase = (databaseKey, key) => {
  const loadingKey = key || `admin-download-database--${Date.now()}`;
  loadingStore.add(loadingKey);
  const user = auth.currentUser();
  if (user) {
    user.jwt()
      .then(
        () => fetch('/.netlify/functions/admin-download-database', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${user.token.access_token}`,
          },
          body: JSON.stringify({ databaseKey }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error || !data.fauna) {
              notificationStore.addNotification({
                preHeader: 'Could not download the database',
                message: `We got the following error: ${data.error || 'unknown problem'}`,
              });
              loadingStore.remove(loadingKey);
            } else {
              /*
              console.log(data.fauna);
              */
              const blobber = new Blob([
                `faunaID,netlifyID,sageID,goCardlessID,email,name,phone,street,town,postcode,genderid,ethnicity,orientation,study,graduation,grade,speciality\n${data.fauna.map(
                  // eslint-disable-next-line no-underscore-dangle
                  (member) => `"${member.id || ''}","${member.netlifyID || ''}","${member.sageID || ''}","${member.goCardlessID || ''}","${member.email || ''}","${member.name || ''}","${member.phone || ''}","${member.street || ''}","${member.town || ''}","${member.postcode || ''}","${member.genderid || ''}","${member.ethnicity || ''}","${member.orientation || ''}","${member.study || ''}","${member.graduation || ''}","${member.grade || ''}","${member.speciality || ''}"`,
                ).join('\n')}`,
              ], { type: 'text/csv' });
              const file = window.URL.createObjectURL(blobber);
              loadingStore.remove(loadingKey);
              window.location.assign(file);
            }
          }),
      )
      .catch(
        (error) => {
          notificationStore.addNotification({
            preHeader: 'Could not download the database',
            message: `We got the following error: ${error.message || 'unknown problem'}`,
          });
          loadingStore.remove(loadingKey);
        },
      );
  } else {
    notificationStore.addNotification({
      preHeader: 'Please Log In As Admin',
      message: 'To use this function you must be an admin.',
    });
    loadingStore.remove(loadingKey);
  }

  return {};
};

/** Exporting everything
 *  ------------------------------------------------------------------------------------------------
**/
const authExport = {
  loggedIn,
  signedUp,
  errorMessages,
  userInfo,
  userDetails,
  loadFullDetails,
  goCardless,
  mailchimp,
  authReady,
  logIn,
  logOut,
  signUp,
  recovery,
  update,
  clearNotificationById,
  loadForbiddenKnowledge,
  addAnError,
  updateUserDetails,
  newMandate,
  mandateReflow,
  cancelMandate,
  updateMandate,
  updateMailchimp,
  adminCheckDetails,
  adminChangeStatus,
  adminCreateMember,
  adminBulkUpload,
  adminDeleteMember,
  adminDownloadDatabase,
};

export default authExport;
