<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // translation store
  import translationStore from './store--translations';

  // loading state store
  import loadingStore from './store--loading';

  // svelte components
  import ContentSection from './ContentSection.svelte';
  import Header from './Header.svelte';
  import RichText from './RichText.svelte';
  import Cta from './Cta.svelte';
  import AuthErrorsNotificationList from './AuthErrorsNotificationList.svelte';

  // auth is the GoTrue client
  // eslint-disable-next-line import/prefer-default-export
  // export let auth;

  // sign up form elements
  let email;
  let goCardlessID = null;
  let name;
  let phone;
  let street;
  let town;
  let postcode;
  let qualification;
  let genderid;
  let ethnicity;
  let orientation;
  let study;
  let graduation;
  let grade;
  let speciality;

  // the translation key for this component...
  const translationKey = 'membership';

  // set when a new sign up attempt is made - let's the button reflect loading status
  let loadingKey = '';

  let unsubscribeTranslations;
  let translations;
  let unsubscribeAuthReady;
  let authReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  let userInfo;
  let unsubscribeUserInfo;
  let loadingState;
  let unsubscribeLoadingState;
  onMount(
    () => {
      unsubscribeTranslations = translationStore.subscribe(
        (state) => {
          translations = state;
        },
      );

      unsubscribeAuthReady = authObject.authReady.subscribe(
        (state) => {
          authReady = state;
        },
      );

      unsubscribeLoggedIn = authObject.loggedIn.subscribe(
        (state) => {
          loggedIn = state;
        },
      );

      unsubscribeUserInfo = authObject.userInfo.subscribe(
        (state) => {
          userInfo = state;
        },
      );

      unsubscribeLoadingState = loadingStore.subscribe(
        (state) => {
          loadingState = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeTranslations();
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
      unsubscribeUserInfo();
      unsubscribeLoadingState();
    },
  );

  // create member
  const signUp = () => {
    const missing = [];
    if (!email) {
      missing.push(
        (translations && translations[translationKey].formValidation.email)
        || 'Email is a required field',
      );
    }


    if (
      missing.length > 0
    ) {
      // true replaces old errors instead of stacking
      authObject.addAnError(missing.join('<br>'), true);
    } else {
      loadingKey = `signup--${Date.now()}`;
      authObject.adminCreateMember(email, {
        goCardlessID,
        name,
        phone,
        street,
        town,
        postcode,
        genderid,
        ethnicity,
        orientation,
        study,
        graduation,
        grade,
        speciality,
      }, loadingKey);
    }
  };


  // check member details
  let checkNetlifyID;
  let checkDetailsLoadingKey = '';

  const checkDetails = () => {
    checkDetailsLoadingKey = `admin-check-details--${Date.now()}`;
    authObject.adminCheckDetails(checkNetlifyID, checkDetailsLoadingKey);
  };


  // delete member
  let deleteCheckText;
  let deleteNetlifyID;
  let deleteMemberLoadingKey = '';

  const deleteMember = () => {
    if (deleteCheckText !== 'DELETE') {
      alert('Please type DELETE in the text box to confirm this action');
    }

    deleteCheckText = '';
    deleteMemberLoadingKey = `admin-delete-member--${Date.now()}`;
    authObject.adminDeleteMember(deleteNetlifyID, deleteMemberLoadingKey);
  };


  // download database
  let databaseKey;
  let downloadDatabaseLoadingKey = '';

  const downloadDatabase = () => {
    downloadDatabaseLoadingKey = `admin-download-database--${Date.now()}`;
    authObject.adminDownloadDatabase(databaseKey, downloadDatabaseLoadingKey);
  };


  // change member status
  let changeNetlifyID;
  let changeRole;
  let changeStatusLoadingKey;

  const changeStatus = () => {
    changeStatusLoadingKey = `admin-change-status--${Date.now()}`;
    authObject.adminChangeStatus(changeNetlifyID, changeRole, changeStatusLoadingKey);
  };


  // bulk upload
  let files;
  let bulkLoadingKey;
  const reader = new FileReader();
  reader.onload = () => {
    const fileContent = JSON.parse(reader.result);
    console.log(fileContent);
    authObject.adminBulkUpload(fileContent, bulkLoadingKey);
  };

  const bulkUpload = () => {
    if (files && files[0]) {
      bulkLoadingKey = `admin-bulk-load--${Date.now()}`;
      reader.readAsText(files[0]);
    }
  };
</script>


<!-- LOADING -->
{#if !authReady}
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--loading'
  >
    <RichText
      content={translations && translations?.[translationKey]?.loading?.loadingMessage}
    />
  </ContentSection>
{/if}


<!-- LOGGED IN - We have a user object! -->
{#if
  authReady
  && loggedIn
  && userInfo
  && userInfo.app_metadata.roles
  && userInfo.app_metadata.roles.includes('admin')
}
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
  >
    <Header
      preHeader='Member Management'
      header='Contents'
    />

    <RichText content={'<a href="#download-database">Download Member Database</a>'} />
    <RichText content={'<a href="#check-member">Check Member Details</a>'} />
    <RichText content={'<a href="#change-member-status">Change Member Status</a>'} />
    <RichText content={'<a href="#new-member">Add a Member</a>'} />
    <RichText content={'<a href="#delete-member">Delete a Member</a>'} />
  </ContentSection>


  <!-- DOWNLOAD DATABASE -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='download-database'
  >
    <Header
      header='Download Member Database'
    />

    <RichText content={'You need to provide a valid DATABASE_DOWNLOAD_KEY to download the database.'} />

      <form
        class="form form--with-sections"
        on:submit|preventDefault={downloadDatabase}
      >
        <div class="Wrap Wrap--inputs u-flows">
          <section
            class="form__section u-flows"
          >
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="databaseKey"
              >
                {'Database download key *'}
              </label>
              <input
                bind:value={databaseKey}
                type="text"
                name="databaseKey"
                id="databaseKey"
                required
                class="use-colour--foreground"
              >
            </div>
          </section>

          <section
            class="form__section u-flows"
          >
            <Cta
              content={'Download Database'}
              click={downloadDatabase}
              submit={true}
              align='left'
              loading={loadingState.indexOf(downloadDatabaseLoadingKey) !== -1}
            />
          </section>
        </div>
      </form> <!-- .form -->
  </ContentSection>



  <!-- CHECK DETAILS -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='check-member'
  >
    <Header
      header='Check Member Details'
    />

    <RichText content={'You can double check whether a NetlifyID exists and what the membership status is for that ID here. You will also get a members name and email address to verify that you are going to edit the correct member.<br><br>For full details you should look in the database.'} />

    <form
      class="form form--with-sections"
      on:submit|preventDefault={checkDetails}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="netlifyID"
            >
              {'NetlifyID *'}
            </label>
            <input
              bind:value={checkNetlifyID}
              type="text"
              name="netlifyID"
              id="netlifyID"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <Cta
            content={'Check Details'}
            click={checkDetails}
            submit={true}
            align='left'
            loading={loadingState.indexOf(checkDetailsLoadingKey) !== -1}
          />
        </section>
      </div>
    </form> <!-- .form -->
  </ContentSection>



  <!-- CHANGE STATUS -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='change-member-status'
  >
    <Header
      header='Change Member Status'
    />

    <RichText content={'Check the current status for an ID first using the section above.'} />

    <RichText content={'Here you can manually set a member to active or lapsed status. Only active members can access exclusive content on the site.'} />

    <form
      class="form form--with-sections"
      on:submit|preventDefault={changeStatus}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="netlifyID"
            >
              {'NetlifyID *'}
            </label>
            <input
              bind:value={changeNetlifyID}
              type="text"
              name="netlifyID"
              id="netlifyID"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated form__input-group--select use-colour--foreground">
            <select
              bind:value={changeRole}
              name="qualification"
              id="qualification"
              class="use-colour--foreground"
              required
            >
              <option value="new" selected disabled>
                {'Select new status'}
              </option>
              <option value="active">
                {'Active'}
              </option>
              <option value="lapsed">
                {'Lapsed'}
              </option>
            </select>
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <Cta
            content={'Change Status'}
            click={changeStatus}
            submit={true}
            align='left'
            loading={loadingState.indexOf(changeStatusLoadingKey) !== -1}
          />
        </section>
      </div>
    </form> <!-- .form -->
  </ContentSection>




  <!-- ADD NEW MEMBER -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='new-member'
  >
    <Header
      header='Add an active Member'
    />

    <RichText
      content={'You are not required to fill all of the fields that a member normally would have to, although it is recommended that you fill in as many as you can.'}
    />

    <RichText
      content={'<strong>Members added through this form will have active accounts - even if you do not provide GoCardless IDs</strong><br><br>if you want them to have inactive accounts you will have to update them after creation, or ask them to create an account through the normal sign up page!'}
    />

    <RichText
      content={'New members will be assigned a random password and should use the forgotten password functionality to login after their account is created.<br><br>They will not receive an automated email about their account creation.'}
    />

    <form
      class="form form--with-sections"
      id="admin-create-user-form"
      on:submit|preventDefault={signUp}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {'Required'}
          </h5>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="email"
            >
              {'Email'}
            </label>
            <input
              bind:value={email}
              type="text"
              name="email"
              id="email"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>


        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {'GoCardless'}
          </h5>
          <p class="form__section-instructions use-colour--foreground">
            {'If this member already has a GoCardless subscription you can enter the subscription ID here.'}<br><br><strong>
              {'It MUST be the subscription ID and not the customer or payment ID.'}
              </strong>
          </p>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="goCardlessID"
            >
              {'Subscription ID'}
            </label>
            <input
              bind:value={goCardlessID}
              type="text"
              name="goCardlessID"
              id="goCardlessID"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {'Normally Required'}
          </h5>
          <p class="form__section-instructions use-colour--foreground">
            {'For member signup these are normally all required. You should ideally include them all for new members (although you do not have to).'}
          </p>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="name"
            >
              {
                (translations && translations[translationKey].formLabels.nameLabel)
                || 'Name *'
              }
            </label>
            <input
              type="text"
              bind:value={name}
              name="name"
              id="name"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="phone"
            >
            {
              (translations && translations[translationKey].formLabels.phoneLabel)
              || 'Phone Number *'
            }
            </label>
            <input
              type="tel"
              bind:value={phone}
              name="phone"
              id="phone"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="street"
            >
              {
                (translations && translations[translationKey].formLabels.streetLabel)
                || 'Street Address *'
              }
            </label>
            <input
              type="text"
              bind:value={street}
              name="street"
              id="street"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="town"
            >
              {
                (translations && translations[translationKey].formLabels.townLabel)
                || 'Town *'
              }
            </label>
            <input
              type="text"
              bind:value={town}
              name="town"
              id="town"
              required
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="postcode"
            >
              {
                (translations && translations[translationKey].formLabels.postCodeLabel)
                || 'Post Code *'
              }
            </label>
            <input
              type="text"
              bind:value={postcode}
              name="postcode"
              id="postcode"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {
              (translations && translations[translationKey].formLabels.qualificationsHeader)
              || 'Qualifications'
            }
          </h5>
          <div class="form__input-group--integrated form__input-group--select use-colour--foreground">
            <select
              bind:value={qualification}
              name="qualification"
              id="qualification"
              class="use-colour--foreground"
              required
            >
              <option value="unselected" selected disabled>
                {
                  (translations && translations[translationKey].formLabels.defaultQualificationText)
                  || 'Select your qualification level'
                } *
              </option>
              <option value="student">
                {
                  (translations && translations[translationKey].formLabels.studentQualificationOption)
                  || 'I am a student'
                }
              </option>
              <option value="qualified">
                {
                  (translations && translations[translationKey].formLabels.qualifiedQualificationOption)
                  || 'I am qualified'
                }
              </option>
            </select>
          </div>
        </section>

        {#if qualification === 'student'}
          <section
            class="form__section u-flows"
          >
            <h5 class="form__section-title use-colour--foreground">
              {
                (translations && translations[translationKey].formLabels.studentsHeader)
                || 'For student members'
              }
            </h5>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="study"
              >
                {
                  (translations && translations[translationKey].formLabels.studyLabel)
                  || 'Place of Study'
                }
              </label>
              <input
                type="text"
                bind:value={study}
                name="study"
                id="study"
                required
                class="use-colour--foreground"
              >
            </div>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="graduation"
              >
                {
                  (translations && translations[translationKey].formLabels.graduationLabel)
                  || 'Year of Graduation'
                }
              </label>
              <input
                type="text"
                bind:value={graduation}
                name="graduation"
                id="graduation"
                required
                class="use-colour--foreground"
              >
            </div>
          </section>
        {/if}


        {#if qualification === 'qualified'}
          <section
            class="form__section u-flows"
          >
            <h5 class="form__section-title use-colour--foreground">
              {
                (translations && translations[translationKey].formLabels.qualifiedHeader)
                || 'For qualified members'
              }
            </h5>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="grade"
              >
                {
                  (translations && translations[translationKey].formLabels.gradeLabel)
                  || 'Grade'
                }
              </label>
              <input
                type="text"
                bind:value={grade}
                name="grade"
                id="grade"
                class="use-colour--foreground"
              >
            </div>
            <div class="form__input-group--integrated">
              <label
                class="use-colour--foreground" for="speciality"
              >
                {
                  (translations && translations[translationKey].formLabels.specialityLabel)
                  || 'Speciality'
                }
              </label>
              <input
                type="text"
                bind:value={speciality}
                name="speciality"
                id="speciality"
                class="use-colour--foreground"
              >
            </div>
          </section>
        {/if}

        <section
          class="form__section u-flows"
        >
          <h5 class="form__section-title use-colour--foreground">
            {
              (translations && translations[translationKey].formLabels.OptionalHeader)
              || 'Optional'
            }
          </h5>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="genderid"
            >
              {
                (translations && translations[translationKey].formLabels.genderLabel)
                || 'Gender Identity'
              }
            </label>
            <input
              type="text"
              bind:value={genderid}
              name="genderid"
              id="genderid"
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="ethnicity"
            >
              {
                (translations && translations[translationKey].formLabels.ethnicityLabel)
                || 'Ethnicity'
              }
            </label>
            <input
              type="text"
              bind:value={ethnicity}
              name="ethnicity"
              id="ethnicity"
              class="use-colour--foreground"
            >
          </div>
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="orientation"
            >
              {
                (translations && translations[translationKey].formLabels.orientationLabel)
                || 'Sexual Orientation'
              }
            </label>
            <input
              type="text"
              bind:value={orientation}
              name="orientation"
              id="orientation"
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <Cta
            content={translations && translations?.[translationKey]?.loggedOut?.signUpButton}
            click={signUp}
            submit={true}
            align='left'
            loading={loadingState.indexOf(loadingKey) !== -1}
          />
        </section>
      </div>
    </form> <!-- .form -->

    <AuthErrorsNotificationList />
  </ContentSection>


  <!-- DELETE A MEMBER -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='delete-member'
  >
    <Header
      header='Delete a Member'
    />

    <RichText content={'This will delete all data from your Netlify account and from the database for the given member.'} />

    <RichText content={'It will not remove their data from your mailing list or from GoCardless. It will not cancel direct debits that are still active. If a member has requested that you totally delete their information you will need to manually remove them from your mailing list and terminate their direct debit then remove their details from the GoCardless admin.'} />

    <RichText content={'<strong>This is not reversible. Please make sure you have a backup of the database information before you delete any members!</strong>'} />

    <form
      class="form form--with-sections"
      on:submit|preventDefault={deleteMember}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="deleteNetlifyID"
            >
              {'NetlifyID *'}
            </label>
            <input
              bind:value={deleteNetlifyID}
              type="text"
              name="deleteNetlifyID"
              id="deleteNetlifyID"
              required
              class="use-colour--foreground"
            >
          </div>

          <div class="form__input-group--integrated">
            <label
              class="use-colour--foreground" for="netlifyID"
            >
              {'Type the word: DELETE'}
            </label>
            <input
              bind:value={deleteCheckText}
              type="text"
              name="delete"
              id="delete"
              required
              class="use-colour--foreground"
            >
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
          <Cta
            content={'Delete Member'}
            click={deleteMember}
            submit={true}
            align='left'
            loading={loadingState.indexOf(deleteMemberLoadingKey) !== -1}
          />
        </section>
      </div>
    </form> <!-- .form -->
  </ContentSection>


  <!-- Bulk Upload -->
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-in'
    id='bulk-upload'
  >
    <Header
      header='Bulk Upload'
    />
    <RichText content={'You probably don\'t need to use this. It allows for a bulk upload of new members through a correcly formatted json file.'} />

    <form
      class="form form--with-sections"
      on:submit|preventDefault={bulkUpload}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <section
          class="form__section u-flows"
        >
          <div class="form__input-group--integrated">
            <input type="file" bind:files>
          </div>
        </section>

        <section
          class="form__section u-flows"
        >
        <Cta
          content={'bulk upload'}
          click={bulkUpload}
          submit={true}
          align='left'
          loading={loadingState.indexOf(bulkLoadingKey) !== -1}
        />
        </section>
      </div>
    </form> <!-- .form -->
  </ContentSection>
{:else if authReady}
  <ContentSection
    className='AdminFunctions__content AdminFunctions__content--logged-out'
  >
    <Header
      preHeader='Admins Only'
      header='Unauthorised'
    />
    <RichText
      content='This page is only for admins. Please log in to an account with admin privileges.'
      align='left'
    />
  </ContentSection>
{/if}
