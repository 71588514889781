const hideNav = (e, nav) => {
  // console.log('transition ended');
  // only on transitions 'out' i.e. not js--nav-active
  if (
    (!nav.classList.contains('js--nav-active'))
    && e.propertyName === 'opacity'
  ) {
    // console.log('hiding');
    nav.classList.remove('visible');
  }
};

const open = (nav) => {
  // don't open if already open...
  if (!nav.classList.contains('js--nav-active')) {
    nav.classList.add('js--nav-active');
    nav.classList.add('visible');
    nav.parentNode.classList.add('js--contains-active-nav');
  }
};

const close = (nav) => {
  nav.classList.remove('js--nav-active');
  nav.parentNode.classList.remove('js--contains-active-nav');
};


export const init = () => {
  const nav = document.querySelector('.js--nav-to-toggle');
  const butts = document.querySelectorAll('.js--nav-toggle');
  const back = document.querySelector('.js--nav-to-toggle__back');
  let navLinks = [];

  if (nav) {
    navLinks = nav.querySelectorAll('a');
  }

  (butts || []).forEach(
    (butt) => {
      butt.addEventListener(
        'click',
        (e) => {
          // we open the popup regardless but only prevent the default for left clicks.
          // means we can still open a new tab or right click...
          // ref: https://www.jacklmoore.com/notes/click-events/
          if (!(e.which > 1 || e.shiftKey || e.altKey || e.metaKey || e.ctrlKey)) {
            e.preventDefault();
          }

          if (nav.classList.contains('js--nav-active')) {
            close(nav);
          } else {
            open(nav);
          }
        },
        false,
      );
    },
  );

  // all links inside the nav close it on click instantly (i.e. no animation out)
  (navLinks || []).forEach(
    (navLink) => {
      navLink.addEventListener(
        'click',
        () => {
          close(nav);
          nav.classList.remove('visible');
        },
        false,
      );
    },
  );

  // keyboard focus should open and close...
  if (nav) {
    nav.addEventListener(
      'focusin',
      open,
      false,
    );

    nav.addEventListener(
      'focusout',
      close,
      false,
    );
  }

  // we hide the nav visually only after transitions end
  if (back) {
    back.addEventListener(
      'transitionend',
      (e) => hideNav(e, nav),
      false,
    );
  }
};

export default init;
