<script>
  import { onDestroy, onMount } from 'svelte';
  import colourStore from './store--colour';

  let currentMode = 0;
  let storeMode = -1;
  let unsubscribe;

  // const icons = [
  //   '🌞',
  //   '🌚',
  // ];

  const modes = [
    'light',
    'dark',
  ];

  let userPref = 0;
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
    userPref = 1;
  }

  let unsubcribe;
  onMount(() => {
    unsubscribe = colourStore.subscribe(
      (state) => {
        storeMode = state;
        if (state === -1) {
          currentMode = 0; // use white if there's nothing in localstorage
        } else {
          currentMode = state;
        }

        document.querySelector('html').classList.remove('light-mode');
        document.querySelector('html').classList.remove('dark-mode');
        document.querySelector('html').classList.add(`${modes[currentMode]}-mode`);
      },
    );
  });
  onDestroy(() => unsubcribe());

  const dark = () => {
    colourStore.set(1);
  };

  const light = () => {
    colourStore.set(0);
  };
</script>

<div class="ColourModeSelector">
  <nav class="nav u-gaps">
    <ul>
      <li class={ currentMode === 0 ? 'nav__item nav__item--active' : 'nav__item'}>
        <button on:click={light}>Light Mode</button>
      </li>
      <li class={ currentMode === 1 ? 'nav__item nav__item--active' : 'nav__item'}>
        <button on:click={dark}>Dark Mode</button>
      </li>
      {#if storeMode > -1}
        <!-- <li class="nav__item">
          <button on:click={colourStore.reset}>Use System Preference</button>
        </li> -->
      {/if}
    </ul>
  </nav>
</div>
