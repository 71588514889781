<script>
  // svelte imports
  import {
    onDestroy,
    onMount,
    tick,
  } from 'svelte';

  import { fade } from 'svelte/transition';

  import { init as formsInit } from '../forms';
  import { init as genericIntersectionInit } from '../generic-intersection';

  // auth
  import authObject from './auth';

  // prop for the members content to load - specified in data-content on the DOM node
  export let content;
  let htmlContent = null;
  let unencodedContent;

  // when htmlContent changes we generate an unencoded version
  $: {
    if (htmlContent) {
      const txt = document.createElement('textarea');
      txt.innerHTML = htmlContent;
      unencodedContent = txt.value;
      // after the next tick we can assume this has made it to the DOM
      // so we can reapply global (non-svelte) listeners...
      // TODO - this isn't a great mixing of methodologies!
      tick().then(() => {
        formsInit();
        genericIntersectionInit();
      });
    }
  }

  let unsubscribeAuthReady;
  let authReady;
  let prevAuthReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  let prevLoggedIn;

  // if auth status or loggedIn status change we re-fetch the members only content
  $: {
    if (authReady !== prevAuthReady || loggedIn !== prevLoggedIn) {
      // console.log(prevAuthReady, authReady);
      // console.log(prevLoggedIn, loggedIn);

      prevAuthReady = authReady;
      prevLoggedIn = loggedIn;

      // console.log('something changed -loading forbidden content:', content);
      authObject.loadForbiddenKnowledge(content).then((loadedContent) => {
        htmlContent = loadedContent;
      });
    }
  }

  onMount(() => {
    unsubscribeAuthReady = authObject.authReady.subscribe(
      (state) => {
        authReady = state;
      },
    );

    unsubscribeLoggedIn = authObject.loggedIn.subscribe(
      (state) => {
        loggedIn = state;
      },
    );
  });

  onDestroy(
    () => {
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
    },
  );
</script>


{#if unencodedContent}
  <div
    class="ForbiddenKnowledge"
    in:fade
  >
    {@html unencodedContent}
  </div>
{/if}
