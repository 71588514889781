<script>
  export let content;
  export let click;
  export let submit = false;
  export let align = 'left';
  export let loading = false;

  let unencodedContent;
  $: {
    const txt = document.createElement('textarea');
    txt.innerHTML = content;
    unencodedContent = txt.value;
  }
</script>

<div
  class={`CTA CTA--align-${align}`}
>
  <div class="Wrap Wrap--readable u-flows">
    <button
      class={`CTA__a ${loading ? 'js--loading' : ''} use-background--foreground use-colour--foreground`}
      type={submit ? 'submit' : 'button'}
      on:click|preventDefault={click}
    >
      <span class="CTA__loader">
        <span class="CTA__spinner use-colour--background">
          <div class="SvgWrap">
          <div class="SvgWrap__padder" style="padding-bottom: 100%;"></div>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48" role="img" aria-label="loading indicator">
          <path fill-opacity=".75" fill="#fff" d="M45.57433565 22.13993563h-2.461108V11.6693596l1.230554 1.8247152 1.230554-1.8247152v10.47057603z"></path>
          <path fill="#fff" d="M24.0000536.0000044c-.31829674.00009125-.32030435.00155133-.47981774.00474525C10.48686031.26026309.0000412 10.90550053.0000412 24.0000168c0 13.24599924 10.75401316 24.0000124 24.0000124 24.0000124 13.21360379 0 23.93312263-10.67836293 23.99973864-23.87636215.0001825-.04115591-.00410647-.04991637.00027376-.12365025 0-5.47766062-7.44000384-5.47318914-7.44000384 0-.00492776.10138408-.00018251.10120157-.00063879.15166548-.08149053 9.0760199-7.4640951 16.40834308-16.55936977 16.40834308-9.13971575 0-16.56000856-7.42029281-16.56000856-16.56000856 0-9.01597424 7.205114-16.34911871 16.17153688-16.55553707.12912554-.0030114.11936127-.01204563.38847168-.00447149 5.48532603 0 5.50750094-7.44000384 0-7.44000384zm20.2585656 23.3612288c.95689783 0 1.7338412.77694337 1.7338412 1.7338412 0 .95689783-.77694337 1.7338412-1.7338412 1.7338412-.95689783 0-1.7338412-.77694337-1.7338412-1.7338412 0-.95689783.77694337-1.7338412 1.7338412-1.7338412z"></path>
          <image src="/images/fallback/loader--opt.png" xlink:href=""></image>
          </svg>
          </div>
        </span>
      </span>

      <span class="CTA__text use-colour--background">
        {content}
        <slot></slot>
      </span>
    </button>
  </div>
</div>
