<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // svelte components
  import NotificationList from './NotificationList.svelte';

  let authErrors = [];
  let unsubscribeAuthErrors;

  onMount(
    () => {
      unsubscribeAuthErrors = authObject.errorMessages.subscribe(
        (state) => {
          authErrors = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeAuthErrors();
    },
  );
</script>


<NotificationList
  notifications={authErrors}
  removeById={authObject.clearNotificationById}
/>
