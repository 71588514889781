<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // global notification store
  import notificationStore from './store--notifications';

  // svelte components
  import NotificationList from './NotificationList.svelte';

  let notifications = [];
  let unsubscribeNotifications;

  onMount(
    () => {
      unsubscribeNotifications = notificationStore.subscribe(
        (state) => {
          notifications = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeNotifications();
    },
  );
</script>


<NotificationList
  notifications={notifications}
  removeById={notificationStore.removeNotificationById}
  align={'center'}
/>
