/** fixing vertical header sizes
 *  ------------------------------------------------------------------------------------------------
 *  using a debounced resize listener...
**/
const fitText = (container) => {
  const availableSpace = container.clientWidth;
  const span = container.querySelector('span'); // the text inside the container
  const containerWidth = span.clientWidth; // width of the text including overflow
  const currentScaling = parseFloat(span.style.fontSize || '1em') || 1; // current em scaling
  const proportion = availableSpace / containerWidth; // proportion of available to taken space
  // proportion is what we would need to scale the container span by to make it fill the
  // available space exactly. We don't want to go bigger than 1 em though so...
  const newEms = Math.min((proportion * currentScaling), 1); // don't go higher than 1em

  span.style.fontSize = `${newEms}em`;
};

const checkVerticalHeaderFits = (vHead, header, pre) => {
  // don't do anything if the header isn't vertical
  const { position } = getComputedStyle(vHead);
  if (position !== 'static' || position !== 'relative') {
    // console.log('sizing');

    // the fitText function scales text down from 1em or back up to 1em if there's available space
    // it won't exceed 1em
    if (header) {
      fitText(header);
    }
    if (pre) {
      fitText(pre);
    }
  }
  vHead.classList.add('js--sized');
};

let resizeTime;
const verticalHeaderResizeHandler = (vHead, header, pre) => {
  clearTimeout(resizeTime);
  resizeTime = setTimeout(
    () => { checkVerticalHeaderFits(vHead, header, pre); },
    100,
  );
};

export const init = () => {
  const vHead = document.querySelector('.site-main:not(.site-main--home) .VerticalHeader');
  if (vHead) {
    const header = vHead.querySelector('.HeaderText__header');
    const pre = vHead.querySelector('.HeaderText__pre');
    checkVerticalHeaderFits(vHead, header, pre);
    window.addEventListener('resize', () => {
      verticalHeaderResizeHandler(vHead, header, pre);
    }, false);
  }
};

export default init;
