/** Global Notifications Store
 *  ------------------------------------------------------------------------------------------------
**/
import { writable } from 'svelte/store';

const store = writable([]);

const removeNotificationById = (id) => {
  store.update(
    (notifications) => notifications.filter((notification) => notification.id !== id),
  );
};

const addNotification = (notification) => {
  const newNotification = {};
  if (notification.message) {
    newNotification.message = notification.message;
  }
  if (notification.header) {
    newNotification.header = notification.header;
  }
  if (notification.preHeader) {
    newNotification.preHeader = notification.preHeader;
  }

  store.update(
    (existingNotifications) => [
      ...existingNotifications,
      {
        id: Date.now(),
        ...newNotification,
      },
    ],
  );
};

// we export our own object
// which allows us to control store business logic away from our components
// it also means we can exclude `set` and `update` methods - we never want them called directly
const notificationStore = {
  subscribe: store.subscribe,
  removeNotificationById,
  addNotification,
};

export default notificationStore;
