<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // translation store
  import translationStore from './store--translations';

  // loading state store
  import loadingStore from './store--loading';

  // svelte components
  import Header from './Header.svelte';
  import ContentSection from './ContentSection.svelte';
  import RichText from './RichText.svelte';
  // import Cta from './Cta.svelte';
  import AuthErrorsNotificationList from './AuthErrorsNotificationList.svelte';

  // the translation key for this component...
  const translationKey = 'dd-success';

  let unsubscribeTranslations;
  let translations;
  let unsubscribeAuthReady;
  let authReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  // global loading keys
  let loadingState;
  let unsubscribeLoadingState;
  onMount(
    () => {
      unsubscribeTranslations = translationStore.subscribe(
        (state) => {
          translations = state;
        },
      );

      unsubscribeAuthReady = authObject.authReady.subscribe(
        (state) => {
          authReady = state;
        },
      );

      unsubscribeLoggedIn = authObject.loggedIn.subscribe(
        (state) => {
          loggedIn = state;
        },
      );

      unsubscribeLoadingState = loadingStore.subscribe(
        (state) => {
          loadingState = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeTranslations();
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
      unsubscribeLoadingState();
    },
  );

  $: {
    if (authReady && loggedIn) {
      authObject.mandateReflow();
    }
  }
</script>


<!-- LOADING -->
{#if !authReady}
  <ContentSection
    className='DirectDebit__content DirectDebit__content--loading'
  >
    <!-- Still loading our auth class -->
    <RichText
      content={translations && translations[translationKey].loading.loadingMessage}
      align='center'
    />
  </ContentSection>
{/if}


<!-- LOGGED IN - We have a user object! -->
{#if authReady && loggedIn}
  <ContentSection
    className='DirectDebit__content DirectDebit__content--logged-in'
  >
    <Header
      header={translations && translations[translationKey].loggedIn.header}
      align='center'
      priority='h3'
    />

    <AuthErrorsNotificationList />
  </ContentSection>
{/if}


<!-- LOGGED OUT -->
{#if authReady && !loggedIn}
  <ContentSection
    className='DirectDebit__content DirectDebit__content--logged-out'
  >
    <Header
      header={translations && translations[translationKey].loggedOut.header}
      align='center'
      priority='h3'
    />

    <RichText
      content={translations && translations[translationKey].loggedOut.message}
      align='center'
    />
  </ContentSection>
{/if}
