<script>
  export let content = false;
  export let align = 'left';

  let unencodedContent;
  $: {
    if (content) {
      const txt = document.createElement('textarea');
      txt.innerHTML = content;
      unencodedContent = txt.value;
    }
  }
</script>

<div
  class={`RichText use-colour--foreground RichText--align-${align}`}
>
  <div class="Wrap Wrap--readable u-flows">
    {#if content && content.length > 0}
      <!-- content passed as prop is always single line so wrapped in p tag -->
      <p>
        {@html unencodedContent}
      </p>
    {/if}

    <slot></slot>
  </div>
</div>
