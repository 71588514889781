/** Parent file for handling authentication on the site
 *  ------------------------------------------------------------------------------------------------
**/

// svelte components
import MemberProfile from './svelte/MemberProfile.svelte';
import MemberSignup from './svelte/MemberSignup.svelte';
import AdminFunctions from './svelte/AdminFunctions.svelte';
import MembershipLinks from './svelte/MembershipLinks.svelte';
import ForbiddenKnowledge from './svelte/ForbiddenKnowledge.svelte';
import DirectDebit from './svelte/DirectDebit.svelte';

/** Initialise GoTrue and render any relevant svelte components
 *  ------------------------------------------------------------------------------------------------
**/
export const init = () => {
  // const auth = new GoTrue({
  //   APIUrl: 'https://sleepingkiwi-gladd.co.uk/.netlify/identity',
  //   audience: '',
  //   setCookie: false,
  // });

  /** initialise the profile/login page
   *  ----------------------------------------------------------------------------------------------
  **/
  const profile = document.querySelector('.js--MemberProfile');
  if (profile) {
    // eslint-disable-next-line no-unused-vars
    const memberProfile = new MemberProfile({
      target: profile,
      props: {
        exclusiveContentSlug: profile.getAttribute('data-exclusive-slug'),
      },
    });
  }

  /** initialise the sign up form
   *  ----------------------------------------------------------------------------------------------
  **/
  const signup = document.querySelector('.js--MemberSignUp');
  if (signup) {
    // eslint-disable-next-line no-unused-vars
    const memberSignup = new MemberSignup({
      target: signup,
      props: {},
    });
  }

  /** initialise the sign up/log in button in header
   *  ----------------------------------------------------------------------------------------------
  **/
  const membershipLinkComponents = [];
  const headerLinks = document.querySelectorAll('.js--MembershipLinks');
  (headerLinks || []).forEach((links) => {
    membershipLinkComponents.push(
      new MembershipLinks({
        target: links,
        props: {},
      }),
    );
  });

  /** load members only content
   *  ----------------------------------------------------------------------------------------------
  **/
  const membersContentComponents = [];
  const membersContent = document.querySelectorAll('.js--forbidden-knowledge');
  (membersContent || []).forEach((itemOfMembersContent) => {
    membersContentComponents.push(
      new ForbiddenKnowledge({
        target: itemOfMembersContent,
        props: {
          content: itemOfMembersContent.getAttribute('data-content'),
        },
      }),
    );
  });

  /** load direct debit processing
   *  ----------------------------------------------------------------------------------------------
  **/
  const dd = document.querySelector('.js--DirectDebit');
  if (dd) {
    // eslint-disable-next-line no-unused-vars
    const directDebit = new DirectDebit({
      target: dd,
      props: {},
    });
  }

  /** load admin functions
   *  ----------------------------------------------------------------------------------------------
  **/
  const af = document.querySelector('.js--AdminFunctions');
  if (af) {
    // eslint-disable-next-line no-unused-vars
    const adminFunctions = new AdminFunctions({
      target: af,
      props: {},
    });
  }
}; // init

export default init;
