/** Async translations fetching
 *  ------------------------------------------------------------------------------------------------
 *  All of our translations are in one file but if we ever move to a per-page model then
 *  we would use a derived store
 *  specifically we could do: https://github.com/sveltejs/svelte/issues/2118#issuecomment-492537687
**/
import { readable } from 'svelte/store';

const translationFile = 'translations.json';
export const currentTranslations = readable(
  null,
  // this function gets called only when subscribers go from 0 to 1
  (set) => {
    fetch(`/${translationFile}`)
      .then((response) => response.json())
      .then((data) => set(data));

    // this returned function is called when subscribers go from 1 to 0
    // we could use it to cancel the fetch request if we wanted (Google AbortController)
    // but for a similar effect we just overwrite set so that if the fetch returns when
    // nothing is subscribed it doesn't unexpectedly update the store!
    return () => {
      // eslint-disable-next-line no-param-reassign
      set = () => {};
    };
  },
);

export default currentTranslations;
