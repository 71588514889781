/** Fade background colours on scroll using intersection observers
 *  ------------------------------------------------------------------------------------------------
 *  https://codepen.io/sleepingkiwi/pen/ExPGqjq
**/

const config = {
  rootMargin: '0px',
  // thresholds trigger when the top or bottom 20% intersect
  threshold: [0.2, 0.4, 0.6, 0.8],
};

export const init = (targets, background) => {
  const gotIntersction = (
    ('IntersectionObserver' in window)
    && ('IntersectionObserverEntry' in window)
    && ('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    && ('isIntersecting' in window.IntersectionObserverEntry.prototype)
  );

  if (!gotIntersction) {
    return;
  }

  const prevRatios = {};
  targets.forEach((target) => {
    prevRatios[target.id] = 0;
  });

  if (targets.length > 0) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // console.log(
          //   entry.target.id, entry.isIntersecting ? 'On screen' : 'Off screen',
          //   entry.intersectionRatio > prevRatios[entry.target.id]
          //     ? 'More on screen than before' : '',
          // );

          // true means we're intersecting
          // we also check we're intersecting more than before
          if (
            entry.isIntersecting === true
            && entry.intersectionRatio > prevRatios[entry.target.id]
          ) {
            (document.querySelectorAll('.js--current-intersection-section') || []).forEach(
              (oldLink) => {
                oldLink.classList.remove('js--current-intersection-section');
              },
            );
            (document.querySelectorAll(`a[href="/#${entry.target.id}"]`) || []).forEach(
              (oldLink) => {
                oldLink.classList.add('js--current-intersection-section');
              },
            );
            // remove all old colour classes
            // eslint-disable-next-line no-param-reassign
            background.className = background.className.replace(/(colour--){1}\w*\s*/g, '');
            // add new colour class
            // eslint-disable-next-line no-param-reassign
            background.classList.add(
              `colour--${entry.target.getAttribute('data-background-colour') || 'default'}`,
            );
          }
          prevRatios[entry.target.id] = entry.intersectionRatio;
        });
      },
      config,
    );

    targets.forEach((target) => {
      observer.observe(target);
    });
  }
};

export default init;
