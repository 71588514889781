<script>
  // svelte imports
  import { onDestroy, onMount } from 'svelte';

  // auth
  import authObject from './auth';

  // translation store
  import translationStore from './store--translations';

  // loading state store
  import loadingStore from './store--loading';

  // svelte components
  import VerticalHeader from './VerticalHeader.svelte';
  import Header from './Header.svelte';
  import ContentSection from './ContentSection.svelte';
  import RichText from './RichText.svelte';
  import Cta from './Cta.svelte';
  import AuthErrorsNotificationList from './AuthErrorsNotificationList.svelte';
  import ForbiddenKnowledge from './ForbiddenKnowledge.svelte';

  // prop - so that we can ref the members only content
  export let exclusiveContentSlug = null;

  // login form elements
  let email;
  let password;

  // shared between logged in and out instances...
  let passwordVisible = false;

  const togglePasswordVisibility = () => {
    passwordVisible = !passwordVisible;
  };

  // the translation key for this component...
  const translationKey = 'me';

  let unsubscribeTranslations;
  let translations;
  let unsubscribeAuthReady;
  let authReady;
  let loggedIn;
  let unsubscribeLoggedIn;
  let userInfo;
  let unsubscribeUserInfo;
  // user details from faunaDB
  let userDetails;
  let unsubscribeUserDetails;
  // member details from goCardless (or blank object)
  let goCardless;
  let unsubscribeGoCardless;
  // member details from mailchimp (or null)
  let mailchimp;
  let unsubscribeMailchimp;
  // global loading keys
  let loadingState;
  let unsubscribeLoadingState;
  onMount(
    () => {
      unsubscribeTranslations = translationStore.subscribe(
        (state) => {
          translations = state;
        },
      );

      unsubscribeAuthReady = authObject.authReady.subscribe(
        (state) => {
          authReady = state;
        },
      );

      unsubscribeLoggedIn = authObject.loggedIn.subscribe(
        (state) => {
          loggedIn = state;
        },
      );

      unsubscribeUserInfo = authObject.userInfo.subscribe(
        (state) => {
          userInfo = state;
        },
      );

      unsubscribeUserDetails = authObject.userDetails.subscribe(
        (state) => {
          userDetails = state;
        },
      );

      unsubscribeGoCardless = authObject.goCardless.subscribe(
        (state) => {
          goCardless = state;
        },
      );

      unsubscribeMailchimp = authObject.mailchimp.subscribe(
        (state) => {
          mailchimp = state;
        },
      );

      unsubscribeLoadingState = loadingStore.subscribe(
        (state) => {
          loadingState = state;
        },
      );
    },
  );
  onDestroy(
    () => {
      unsubscribeTranslations();
      unsubscribeAuthReady();
      unsubscribeLoggedIn();
      unsubscribeUserInfo();
      unsubscribeUserDetails();
      unsubscribeGoCardless();
      unsubscribeLoadingState();
      unsubscribeMailchimp();
    },
  );

  // load full details if we're logged in
  $: {
    if (authReady && loggedIn) {
      authObject.loadFullDetails();
    }
  }

  // which text do we display to indicate membership status
  let membershipStatus = 'inactive';
  $: {
    if (userInfo && userInfo.app_metadata) {
      if ((userInfo.app_metadata.roles || []).includes('admin') || (userInfo.app_metadata.roles || []).includes('active')) {
        membershipStatus = 'active';
      } else if ((userInfo.app_metadata.roles || []).includes('lapsed')) {
        membershipStatus = 'lapsed';
      }
    }
  }

  // logged In form elements and defaults
  let emailUpdate;
  let initialEmail;
  $: {
    if (userInfo && userInfo.email !== initialEmail) {
      initialEmail = userInfo.email;
      emailUpdate = userInfo.email;
    }
  }
  let passwordUpdate;

  let name;
  let initialName;
  $: {
    if (userDetails && userDetails.name !== initialName) {
      initialName = userDetails.name;
      name = userDetails.name;
    }
  }
  let phone;
  let initialPhone;
  $: {
    if (userDetails && userDetails.phone !== initialPhone) {
      initialPhone = userDetails.phone;
      phone = userDetails.phone;
    }
  }
  let street;
  let initialStreet;
  $: {
    if (userDetails && userDetails.street !== initialStreet) {
      initialStreet = userDetails.street;
      street = userDetails.street;
    }
  }
  let town;
  let initialTown;
  $: {
    if (userDetails && userDetails.town !== initialTown) {
      initialTown = userDetails.town;
      town = userDetails.town;
    }
  }
  let postcode;
  let initialPostcode;
  $: {
    if (userDetails && userDetails.postcode !== initialPostcode) {
      initialPostcode = userDetails.postcode;
      postcode = userDetails.postcode;
    }
  }
  let genderid;
  let initialGenderid;
  $: {
    if (userDetails && userDetails.genderid !== initialGenderid) {
      initialGenderid = userDetails.genderid;
      genderid = userDetails.genderid;
    }
  }
  let ethnicity;
  let initialEthnicity;
  $: {
    if (userDetails && userDetails.ethnicity !== initialEthnicity) {
      initialEthnicity = userDetails.ethnicity;
      ethnicity = userDetails.ethnicity;
    }
  }
  let orientation;
  let initialOrientation;
  $: {
    if (userDetails && userDetails.orientation !== initialOrientation) {
      initialOrientation = userDetails.orientation;
      orientation = userDetails.orientation;
    }
  }
  let study;
  let initialStudy;
  $: {
    if (userDetails && userDetails.study !== initialStudy) {
      initialStudy = userDetails.study;
      study = userDetails.study;
    }
  }
  let graduation;
  let initialGraduation;
  $: {
    if (userDetails && userDetails.graduation !== initialGraduation) {
      initialGraduation = userDetails.graduation;
      graduation = userDetails.graduation;
    }
  }
  let grade;
  let initialGrade;
  $: {
    if (userDetails && userDetails.grade !== initialGrade) {
      initialGrade = userDetails.grade;
      grade = userDetails.grade;
    }
  }
  let speciality;
  let initialSpeciality;
  $: {
    if (userDetails && userDetails.speciality !== initialSpeciality) {
      initialSpeciality = userDetails.speciality;
      speciality = userDetails.speciality;
    }
  }

  // scroll to the top after log in
  let prevLogInStatus;
  $: {
    if (loggedIn !== prevLogInStatus) {
      if (loggedIn === true) {
        window.scrollTo(0, 0);
      }

      prevLogInStatus = loggedIn;
    }
  }


  // set when a new sign up attempt is made - let's the button reflect loading status
  let logInLoadingKey = '';
  const logIn = () => {
    logInLoadingKey = `login--${Date.now()}`;
    authObject.logIn(email, password, logInLoadingKey);
  };

  const forgotten = () => {
    authObject.recovery(email);
  };

  // set when a new sign up attempt is made - let's the button reflect loading status
  let updateEmailLoadingKey = '';
  const updateEmail = () => {
    updateEmailLoadingKey = `update-email--${Date.now()}`;
    // second arg (true) means we're updating email rather than password
    authObject.update(emailUpdate, true, updateEmailLoadingKey);
  };

  // set when a new sign up attempt is made - let's the button reflect loading status
  let updatePasswordLoadingKey = '';
  const updatePassword = () => {
    updatePasswordLoadingKey = `update-password--${Date.now()}`;
    // second arg (false) means we're updating password rather than email
    authObject.update(passwordUpdate, false, updatePasswordLoadingKey);
  };

  // when cancelling a mandate
  let cancelMandateLoadingKey = '';
  const cancelMandate = () => {
    cancelMandateLoadingKey = `cancel-mandate--${Date.now()}`;
    authObject.cancelMandate(cancelMandateLoadingKey);
  };

  // when creating a mandate
  let newMandateLoadingKey = '';
  const newMandate = (price, description) => {
    newMandateLoadingKey = `new-mandate--${Date.now()}`;
    authObject.newMandate(parseInt(price, 10), description, newMandateLoadingKey);
  };

  // updating your subscription
  let updateMandateLoadingKey = '';
  const updateMandate = (price, description) => {
    updateMandateLoadingKey = `update-mandate--${Date.now()}`;
    authObject.updateMandate(parseInt(price, 10), description, updateMandateLoadingKey);
  };

  // updating mailchimp
  let mailchimpLoadingKey = '';
  const updateMailchimp = (type = 'sub') => {
    mailchimpLoadingKey = `update-mailchimp--${Date.now()}`;
    authObject.updateMailchimp(type, mailchimpLoadingKey);
  };

  // set when a new sign up attempt is made - let's the button reflect loading status
  let updateDetailsLoadingKey = '';
  const updateUserDetails = () => {
    const missing = [];
    if (!name) {
      missing.push(
        (translations && translations[translationKey].formValidation.name)
        || 'Name is a required field',
      );
    }
    if (!phone) {
      missing.push(
        (translations && translations[translationKey].formValidation.phone)
        || 'Phone is a required field',
      );
    }
    if (!street) {
      missing.push(
        (translations && translations[translationKey].formValidation.street)
        || 'Street is a required field',
      );
    }
    if (!town) {
      missing.push(
        (translations && translations[translationKey].formValidation.town)
        || 'Town is a required field',
      );
    }
    if (!postcode) {
      missing.push(
        (translations && translations[translationKey].formValidation.postcode)
        || 'Postcode is a required field',
      );
    }

    // need to have either study & graduation OR grade & speciality
    if (
      (!study || !graduation)
      && (!grade || !speciality)
    ) {
      missing.push(
        (translations && translations[translationKey].formValidation.qualifications)
        || 'If you are a student we require both your Place of Study and Graduation Date. Or if you are qualified we require both your Grade and Speciality',
      );
    }


    if (
      missing.length > 0
    ) {
      authObject.addAnError(missing.join('<br>'));
    } else {
      updateDetailsLoadingKey = `update-details--${Date.now()}`;
      authObject.updateUserDetails({
        name,
        phone,
        street,
        town,
        postcode,
        genderid,
        ethnicity,
        orientation,
        study,
        graduation,
        grade,
        speciality,
      }, updateDetailsLoadingKey);
    }
  };

  // const checkUser = () => {
  //   console.log(userInfo);
  // };
</script>


<!-- headers for authed/un-authed people -->
{#if
  authReady && loggedIn
}
  <VerticalHeader
    header={translations && translations[translationKey].loggedIn.header}
    preHeader={translations && translations[translationKey].loggedIn.preHeader}
  />
{:else}
  <VerticalHeader
    header={translations && translations[translationKey].loggedOut.header}
    preHeader={translations && translations[translationKey].loggedOut.preHeader}
  />
{/if}


<!-- LOADING -->
{#if !authReady}
  <ContentSection
    className='MemberProfile__content MemberProfile__content--loading'
  >
    <!-- Still loading our auth class -->
    <RichText
      content={translations && translations[translationKey].loading.loadingMessage}
    />
  </ContentSection>
{/if}


<!-- LOGGED IN - We have a user object! -->
{#if authReady && loggedIn && userInfo}
  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-in MemberProfile__content--logged-in-welcome'
  >
    <Header
      header={translations && translations[translationKey].accountStatus[membershipStatus]}
      preHeader={`Welcome ${(userDetails ? userDetails.name : false) || (userInfo ? userInfo.email : '')}`}
      align='left'
      priority='h3'
    />

    <RichText
      align='left'
    >
      <p>
        <a href="#membership">{translations && translations[translationKey].introduction.membership}</a>
      </p>
    </RichText>

    <RichText
      align='left'
    >
      <p>
        <strong>{translations && translations[translationKey].introduction.also}</strong>
      </p>
      <p>
        <a href="#details">{translations && translations[translationKey].introduction.details}</a>
      </p>
    </RichText>

    <RichText
      align='left'
    >
      <p>
        <a href="#content">{translations && translations[translationKey].introduction.content}</a>
      </p>
    </RichText>
  </ContentSection>

  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-in MemberProfile__content--logged-in-membership'
    id='membership'
  >
    <Header
      header={'Your Membership'}
      align='left'
    />

    <RichText
      align='left'
    >
      <p>
        <strong>{translations && translations[translationKey].accountStatus[membershipStatus]}</strong>
      </p>
    </RichText>

    {#if goCardless && goCardless.subscription && goCardless.subscription.status === 'cancelled'}
      <RichText
        align='left'
      >
        <p>
          <strong>Your direct debit is not active</strong>. You will no longer be charged

          {#if goCardless.expiration && goCardless.expiration.display}
            and your membership will lapse on <strong>{goCardless.expiration.display}</strong>
          {/if}
        </p>
      </RichText>
    {/if}


      {#if userDetails && userDetails.goCardlessID}
        {#if goCardless}

          <Header
            header={translations && translations[translationKey].directDebitManagement.detailsTitle}
            align='left'
            priority='h4'
          />
          {#if membershipStatus !== 'active'}
            <RichText
              align='left'
            >
            <p><strong>You have a problem with your direct debit set up.</strong></p>
            <p>Normally this happens if your direct debit was cancelled with your bank or there have been payment failures. If you believe this is a mistake please <a href="/#contact">get in touch</a> and we can reactivate your account!</p>

            {#if goCardless.subscription}
              <p>
                <strong>Subscription status:</strong> {goCardless.subscription.status}
              </p>
            {/if}
            {#if goCardless.mandate}
              <p>
                <strong>Mandate status:</strong> {goCardless.mandate.status}
              </p>
            {/if}
            </RichText>
          {/if}

          <RichText
            align='left'
          >
          {#if goCardless.subscription}
            <p>
              {#if goCardless.subscription.name || goCardless.subscription.amount}
                <strong>Membership Type:</strong>
                {#if parseInt(translations[translationKey].membershipLevels.qualifiedPricePence, 10)
                  === parseInt(goCardless.subscription.amount, 10)}
                  {translations && translations[translationKey].membershipLevels.qualifiedName}
                {:else if parseInt(translations[translationKey].membershipLevels.foundationPricePence, 10)
                  === parseInt(goCardless.subscription.amount, 10)}
                  {translations && translations[translationKey].membershipLevels.foundationName}
                {:else if parseInt(translations[translationKey].membershipLevels.studentPricePence, 10)
                  === parseInt(goCardless.subscription.amount, 10)}
                    {translations && translations[translationKey].membershipLevels.studentName}
                {:else if parseInt(translations[translationKey].membershipLevels.additionalPricePence, 10)
                  === parseInt(goCardless.subscription.amount, 10)}
                    {translations && translations[translationKey].membershipLevels.additionalName}
                {:else}
                  {goCardless.subscription.name}
                {/if}
                <br>
              {/if}


              {#if goCardless.subscription.upcoming_payments && goCardless.subscription.upcoming_payments[0]}
                <strong>Next Payment:</strong> £{(goCardless.subscription.amount / 100) || 0} on {goCardless.subscription.upcoming_payments[0].charge_date}<br>
              {/if}


              {#if goCardless.subscription.status === 'cancelled'}
                <strong>Next Payment:</strong> N/A - Direct Debit cancelled<br>
              {/if}


              {#if goCardless.payments && goCardless.payments.payments && goCardless.payments.payments.length > 0}
                <strong>Payment History:</strong><br>-
                {#each goCardless.payments.payments as payment}
                  <br>£{(payment.amount / 100) || 0} on {payment.charge_date}<br>
                  <small>
                    {#if payment.status === 'confirmed' || payment.status === 'paid_out'}
                      Paid
                    {:else if payment.status === 'submitted'}
                      Processing
                    {:else if payment.status === 'pending_submission' || payment.status === 'pending_customer_approval'}
                      Pending
                    {:else if payment.status === 'cancelled'}
                      Cancelled
                    {:else}
                      Failed
                    {/if}
                  </small><br>-
                {/each}
              {/if}
            </p>
          {/if}

          {#if goCardless.bankAccount && goCardless.subscription.status !== 'cancelled'}
            <p>
              {#if goCardless.bankAccount.account_holder_name}
                <strong>Account Holder:</strong> {goCardless.bankAccount.account_holder_name}<br>
              {/if}
              {#if goCardless.bankAccount.account_number_ending}
                <strong>Account Number Ending:</strong> {goCardless.bankAccount.account_number_ending}<br>
              {/if}
              {#if goCardless.bankAccount.bank_name}
                <strong>Bank:</strong> {goCardless.bankAccount.bank_name}<br>
              {/if}
            </p>
          {/if}
          </RichText>

          <!--
            CHANGING DD PRICE
          -->
          {#if goCardless.subscription && goCardless.subscription.amount && goCardless.subscription.status !== 'cancelled'}
            <Header
              header={translations && translations[translationKey].directDebitManagement.updateTitle}
              align='left'
              priority='h4'
            />
            <RichText
              align='left'
            >
            <p>
              {translations && translations[translationKey].directDebitManagement.updateExplanation}
            </p>
            <div class="MemberProfile__dd-links u-flows">
              {#if
                translations
                && translations[translationKey].membershipLevels.qualifiedName
                && translations[translationKey].membershipLevels.qualifiedName !== ''
                && translations[translationKey].membershipLevels.qualifiedPricePence
                && (
                  parseInt(translations[translationKey].membershipLevels.qualifiedPricePence, 10)
                  !== parseInt(goCardless.subscription.amount, 10)
                )
              }
                <Cta
                  content={translations && translations[translationKey].membershipLevels.qualifiedName}
                  click={() => {
                    updateMandate(
                      translations[translationKey].membershipLevels.qualifiedPricePence,
                      translations[translationKey].membershipLevels.qualifiedName,
                    );
                  }}
                  loading={loadingState.indexOf(updateMandateLoadingKey) !== -1}
                  align='left'
                />
              {/if}

              {#if
                translations
                && translations[translationKey].membershipLevels.foundationName
                && translations[translationKey].membershipLevels.foundationName !== ''
                && translations[translationKey].membershipLevels.foundationPricePence
                && (
                  parseInt(translations[translationKey].membershipLevels.foundationPricePence, 10)
                  !== parseInt(goCardless.subscription.amount, 10)
                )
              }
                <Cta
                  content={translations[translationKey].membershipLevels.foundationName}
                  click={() => {
                    updateMandate(
                      translations[translationKey].membershipLevels.foundationPricePence,
                      translations[translationKey].membershipLevels.foundationName,
                    );
                  }}
                  loading={loadingState.indexOf(updateMandateLoadingKey) !== -1}
                  align='left'
                />
              {/if}

              {#if
                translations
                && translations[translationKey].membershipLevels.studentName
                && translations[translationKey].membershipLevels.studentName !== ''
                && translations[translationKey].membershipLevels.studentPricePence
                && (
                  parseInt(translations[translationKey].membershipLevels.studentPricePence, 10)
                  !== parseInt(goCardless.subscription.amount, 10)
                )
              }
                <Cta
                  content={translations[translationKey].membershipLevels.studentName}
                  click={() => {
                    updateMandate(
                      translations[translationKey].membershipLevels.studentPricePence,
                      translations[translationKey].membershipLevels.studentName,
                    );
                  }}
                  loading={loadingState.indexOf(updateMandateLoadingKey) !== -1}
                  align='left'
                />
              {/if}

              {#if
                translations
                && translations[translationKey].membershipLevels.additionalName
                && translations[translationKey].membershipLevels.additionalName !== ''
                && translations[translationKey].membershipLevels.additionalPricePence
                && (
                  parseInt(translations[translationKey].membershipLevels.additionalPricePence, 10)
                  !== parseInt(goCardless.subscription.amount, 10)
                )
              }
                <Cta
                  content={translations[translationKey].membershipLevels.additionalName}
                  click={() => {
                    updateMandate(
                      translations[translationKey].membershipLevels.additionalPricePence,
                      translations[translationKey].membershipLevels.additionalName,
                    );
                  }}
                  loading={loadingState.indexOf(updateMandateLoadingKey) !== -1}
                  align='left'
                />
              {/if}
            </div>
            </RichText>
          {/if}


          <!--
            CANCELLING ACTIVE DD
          -->
          {#if goCardless.mandate && goCardless.mandate.id && goCardless.mandate.status !== 'cancelled'}
            <Header
              header={translations && translations[translationKey].directDebitManagement.cancelTitle}
              align='left'
              priority='h4'
            />
            <RichText
              align='left'
            >
            <p>
              {translations && translations[translationKey].directDebitManagement.cancelExplanation}
            </p>
            <Cta
              content={translations && translations[translationKey].directDebitManagement.cancelLink}
              click={() => {
                cancelMandate();
              }}
              align='left'
              loading={loadingState.indexOf(cancelMandateLoadingKey) !== -1}
            />
            </RichText>
          {:else if !goCardless || !goCardless.mandate || !(goCardless.mandate.status === 'cancelled')}
            <RichText
              align='left'
            >
            <p>
              {translations && translations[translationKey].directDebitManagement.cancelExplanationNoLink}
            </p>
            </RichText>
          {/if}
        {:else if goCardless === null}
        <!-- null goCardless means still loading - explicitly set false/undefined otherwise -->
          <RichText
            align='left'
          >
          <p><strong>{translations && translations[translationKey].directDebitManagement.checking}</strong></p>
          </RichText>
        {:else}
          <RichText
            align='left'
          >
          <p><strong>{translations && translations[translationKey].directDebitManagement.notLoaded}</strong></p>
          </RichText>
        {/if}
      {/if}

      {#if userDetails && (!userDetails.goCardlessID || (goCardless && goCardless.mandate && goCardless.mandate.status === 'cancelled'))}
        <RichText
          align='left'
        >
        <p><strong>{translations && translations[translationKey].directDebitManagement.doNotHave}</strong></p>
        </RichText>

        <!-- No direct debit - the text we show changes based on account status -->
        {#if goCardless && goCardless.mandate && goCardless.mandate.status === 'cancelled'}
          <RichText
            content='You can set up a new direct debit mandate here. Please select your preferred membership level below:'
          />
        {:else if membershipStatus === 'active'}
          <RichText
            content={translations && translations[translationKey].directDebitManagement.activeNoDD}
          />
        {:else}
          <RichText
            content={translations && translations[translationKey].directDebitManagement.inactiveNoDD}
          />
        {/if}

        <RichText>
        <div class="MemberProfile__dd-links u-flows">
          {#if
            translations
            && translations[translationKey].membershipLevels.qualifiedName
            && translations[translationKey].membershipLevels.qualifiedName !== ''
            && translations[translationKey].membershipLevels.qualifiedPricePence
          }
            <Cta
              content={translations[translationKey].membershipLevels.qualifiedName}
              click={() => {
                newMandate(
                  translations[translationKey].membershipLevels.qualifiedPricePence,
                  translations[translationKey].membershipLevels.qualifiedName,
                );
              }}
              loading={loadingState.indexOf(newMandateLoadingKey) !== -1}
              align='left'
            />
          {/if}

          {#if
            translations
            && translations[translationKey].membershipLevels.foundationName
            && translations[translationKey].membershipLevels.foundationName !== ''
            && translations[translationKey].membershipLevels.foundationPricePence
          }
            <Cta
              content={translations[translationKey].membershipLevels.foundationName}
              click={() => {
                newMandate(
                  translations[translationKey].membershipLevels.foundationPricePence,
                  translations[translationKey].membershipLevels.foundationName,
                );
              }}
              loading={loadingState.indexOf(newMandateLoadingKey) !== -1}
              align='left'
            />
          {/if}

          {#if
            translations
            && translations[translationKey].membershipLevels.studentName
            && translations[translationKey].membershipLevels.studentName !== ''
            && translations[translationKey].membershipLevels.studentPricePence
          }
            <Cta
              content={translations[translationKey].membershipLevels.studentName}
              click={() => {
                newMandate(
                  translations[translationKey].membershipLevels.studentPricePence,
                  translations[translationKey].membershipLevels.studentName,
                );
              }}
              loading={loadingState.indexOf(newMandateLoadingKey) !== -1}
              align='left'
            />
          {/if}

          {#if
            translations
            && translations[translationKey].membershipLevels.additionalName
            && translations[translationKey].membershipLevels.additionalName !== ''
            && translations[translationKey].membershipLevels.additionalPricePence
          }
            <Cta
              content={translations[translationKey].membershipLevels.additionalName}
              click={() => {
                newMandate(
                  translations[translationKey].membershipLevels.additionalPricePence,
                  translations[translationKey].membershipLevels.additionalName,
                );
              }}
              loading={loadingState.indexOf(newMandateLoadingKey) !== -1}
              align='left'
            />
          {/if}
        </div>
        </RichText>

        {#if membershipStatus !== 'active'}
        <RichText
          content={translations && translations[translationKey].directDebitManagement.additionalInfo}
        />
        {/if}
      {/if}

      {#if userDetails === null}
        <!-- null userDetails means still loading - explicitly set false/undefined otherwise -->
        <RichText
          align='left'
        >
        <p><strong>{translations && translations[translationKey].directDebitManagement.checking}</strong></p>
        </RichText>
      {/if}
  </ContentSection>



  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-in MemberProfile__content--logged-in-mailing'
    id='mailing-list'
  >
    <!-- MAILCHIMP INTEGRATION -->
    <Header
      preHeader={translations && translations[translationKey].mailingList.preHeader}
      header={translations && translations[translationKey].mailingList.header}
      align='left'
    />
    {#if mailchimp === null}
      <!-- only whilst it loads -->
      <RichText
        content={translations && translations[translationKey].mailingList.checking}
      />
    {:else if mailchimp === 'subscribed'}
      <!-- subscribed -->
      <RichText
        content={translations && translations[translationKey].mailingList.subscribed}
      />
      <RichText>
      <Cta
        content={translations && translations[translationKey].mailingList.unSubCTA}
        click={() => updateMailchimp('unsub')}
        loading={loadingState.indexOf(mailchimpLoadingKey) !== -1}
      />
      </RichText>
    {:else if membershipStatus === 'active'}
      <!-- unsubscribed or error - for active members -->
      <RichText
        content={translations && translations[translationKey].mailingList.notSubscribed}
      />
      <RichText>
      <Cta
        content={translations && translations[translationKey].mailingList.subCTA}
        click={() => updateMailchimp('sub')}
        loading={loadingState.indexOf(mailchimpLoadingKey) !== -1}
      />
      </RichText>
    {:else if membershipStatus !== 'active'}
      <!-- unsubscribed or error for inactive members -->
      <RichText
        content={translations && translations[translationKey].mailingList.notSubscribedInactive}
      />
    {/if}
  </ContentSection>




  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-in MemberProfile__content--logged-in-details'
    id='details'
  >
    <div
      class="ContentColumns ContentColumns--weighted-even ContentColumns--aligned-top ContentColumns--content u-flows"
    >
      <div class="u-flows">
        <Header
          preHeader={translations && translations[translationKey].yourDetails.personalPre}
          header={translations && translations[translationKey].yourDetails.personalHeader}
          align='left'
          priority='h4'
        />
        <RichText
          align='left'
          content={translations && translations[translationKey].yourDetails.personalIntro}
        />
        <form
          class="form form--with-sections"
          on:submit|preventDefault={updateUserDetails}
        >
          <div class="Wrap Wrap--inputs u-flows">
            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.requiredHeader)
                  || 'Required'
                }
              </h5>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="name"
                >
                {
                  (translations && translations[translationKey].formLabels.nameLabel)
                  || 'Name *'
                }
                </label>
                <input
                  type="text"
                  bind:value={name}
                  name="name"
                  id="name"
                  required
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="phone"
                >
                {
                  (translations && translations[translationKey].formLabels.phoneLabel)
                  || 'Phone Number *'
                }
                </label>
                <input
                  type="tel"
                  bind:value={phone}
                  name="phone"
                  id="phone"
                  required
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="street"
                >
                {
                  (translations && translations[translationKey].formLabels.streetLabel)
                  || 'Street Address *'
                }
                </label>
                <input
                  type="text"
                  bind:value={street}
                  name="street"
                  id="street"
                  required
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="town"
                >
                {
                  (translations && translations[translationKey].formLabels.townLabel)
                  || 'Town *'
                }
                </label>
                <input
                  type="text"
                  bind:value={town}
                  name="town"
                  id="town"
                  required
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="postcode"
                >
                {
                  (translations && translations[translationKey].formLabels.postCodeLabel)
                  || 'Post Code *'
                }
                </label>
                <input
                  type="text"
                  bind:value={postcode}
                  name="postcode"
                  id="postcode"
                  required
                  class="use-colour--foreground"
                >
              </div>
            </section>

            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.OptionalHeader)
                  || 'Optional'
                }
              </h5>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="genderid"
                >
                {
                  (translations && translations[translationKey].formLabels.genderLabel)
                  || 'Gender Identity'
                }
                </label>
                <input
                  type="text"
                  bind:value={genderid}
                  name="genderid"
                  id="genderid"
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="ethnicity"
                >
                {
                  (translations && translations[translationKey].formLabels.ethnicityLabel)
                  || 'Ethnicity'
                }
                </label>
                <input
                  type="text"
                  bind:value={ethnicity}
                  name="ethnicity"
                  id="ethnicity"
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="orientation"
                >
                {
                  (translations && translations[translationKey].formLabels.orientationLabel)
                  || 'Sexual Orientation'
                }
                </label>
                <input
                  type="text"
                  bind:value={orientation}
                  name="orientation"
                  id="orientation"
                  class="use-colour--foreground"
                >
              </div>
            </section>

            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.studentsHeader)
                  || 'For student members'
                }
              </h5>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="study"
                >
                {
                  (translations && translations[translationKey].formLabels.studyLabel)
                  || 'Place of Study'
                }
                </label>
                <input
                  type="text"
                  bind:value={study}
                  name="study"
                  id="study"
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="graduation"
                >
                {
                  (translations && translations[translationKey].formLabels.graduationLabel)
                  || 'Year of Graduation'
                }
                </label>
                <input
                  type="text"
                  bind:value={graduation}
                  name="graduation"
                  id="graduation"
                  class="use-colour--foreground"
                >
              </div>
            </section>

            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.qualifiedHeader)
                  || 'For qualified members'
                }
              </h5>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="grade"
                >
                {
                  (translations && translations[translationKey].formLabels.gradeLabel)
                  || 'Grade'
                }
                </label>
                <input
                  type="text"
                  bind:value={grade}
                  name="grade"
                  id="grade"
                  class="use-colour--foreground"
                >
              </div>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="speciality"
                >
                {
                  (translations && translations[translationKey].formLabels.specialityLabel)
                  || 'Speciality'
                }
                </label>
                <input
                  type="text"
                  bind:value={speciality}
                  name="speciality"
                  id="speciality"
                  class="use-colour--foreground"
                >
              </div>
            </section>

            <section
              class="form__section u-flows"
            >
              <Cta
                content={
                  (translations && translations[translationKey].formLabels.updateCTA)
                  || 'Update Your Details'
                }
                click={updateUserDetails}
                align='left'
                submit={true}
                loading={loadingState.indexOf(updateDetailsLoadingKey) !== -1}
              />

              <RichText
                align='left'
              >
                <p>
                  <small>
                    {
                      (translations && translations[translationKey].formLabels.postFormText)
                      || 'If you would like us to delete your GLADD account please get in touch.'
                    }
                  </small>
                </p>
              </RichText>
            </section>
          </div>
        </form> <!-- .form -->

        <AuthErrorsNotificationList />
      </div><!-- column one -->
      <div class="u-flows">
        <Header
          preHeader={translations && translations[translationKey].yourDetails.loginPre}
          header={translations && translations[translationKey].yourDetails.loginHeader}
          align='left'
          priority='h4'
        />
        <RichText
          align='left'
          content={translations && translations[translationKey].yourDetails.loginIntro}
        />
        <form
          class="form form--with-sections"
          on:submit|preventDefault={updateEmail}
        >
          <div class="Wrap Wrap--inputs u-flows">
            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.emailHeader)
                  || 'Your Email Address'
                }
              </h5>
              <p class="form__section-instructions use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.emailSub)
                  || 'Please use a personal email address if you can.'
                }
              </p>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="emailUpdate"
                >
                  {
                    (translations && translations[translationKey].formLabels.emailLabel)
                    || 'Email'
                  }
                </label>
                <input
                  bind:value={emailUpdate}
                  type="email"
                  name="emailUpdate"
                  id="emailUpdate"
                  required
                  class="use-colour--foreground"
                >
              </div>

              <Cta
                content={
                  (translations && translations[translationKey].formLabels.emailCTA)
                  || 'Update Your Email'
                }
                click={updateEmail}
                align='left'
                loading={loadingState.indexOf(updateEmailLoadingKey) !== -1}
              />
            </section>
          </div>
        </form> <!-- .form -->

        <form
          class="form form--with-sections"
          on:submit|preventDefault={updatePassword}
        >
          <div class="Wrap Wrap--inputs u-flows">
            <section
              class="form__section u-flows"
            >
              <h5 class="form__section-title use-colour--foreground">
                {
                  (translations && translations[translationKey].formLabels.passwordHeader)
                  || 'Your Password'
                }
              </h5>
              <div class="form__input-group--integrated">
                <label
                  class="use-colour--foreground" for="password"
                >
                  {
                    (translations && translations[translationKey].formLabels.passwordLabel)
                    || 'New Password'
                  }
                </label>
                {#if passwordVisible }
                <input
                  bind:value={passwordUpdate}
                  type="text"
                  name="password"
                  id="password"
                  required
                  class="use-colour--foreground"
                >
                {:else}
                <input
                  bind:value={passwordUpdate}
                  type="password"
                  name="password"
                  id="password"
                  required
                  class="use-colour--foreground"
                >
                {/if}

                <div class="form__input-group__icon use-colour--foreground">
                  <button
                    class="form__input-group__icon-content"
                    on:click|preventDefault={togglePasswordVisibility}
                    type="button"
                  >
                    <div class="SvgWrap">
                      <div class="SvgWrap__padder" style="padding-bottom: 100%;"></div>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" role="img" aria-label="GLADD">
                        {#if passwordVisible }
                        <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                        {:else}
                        <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                        <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                        <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                        {/if}
                      </svg>
                    </div>
                  </button>
                </div>
              </div>

              <Cta
                content={
                  (translations && translations[translationKey].formLabels.passwordCTA)
                  || 'Update Your Password'
                }
                click={updatePassword}
                align='left'
                loading={loadingState.indexOf(updatePasswordLoadingKey) !== -1}
              />
            </section>
          </div>
        </form> <!-- .form -->
      </div><!-- column two -->
    </div><!-- ContentColumns -->
  </ContentSection>

  {#if exclusiveContentSlug}
    <ContentSection
      className='MemberProfile__content MemberProfile__content--logged-in MemberProfile__content--logged-in-exclusives'
      id='content'
    >
      <Header
        header={translations && translations[translationKey].exclusiveContent.header}
        align='left'
      />

      <ForbiddenKnowledge
        content={exclusiveContentSlug}
      />
    </ContentSection>
  {/if}
{/if}


<!-- LOGGED OUT -->
{#if authReady && (!loggedIn || !userInfo)}
  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-out'
  >
    <Header
      header={translations && translations[translationKey].loggedOut.preFormLarge}
      preHeader={translations && translations[translationKey].loggedOut.preFormSmall}
    />

    <RichText
      content={translations && translations[translationKey].loggedOut.preFormMessage}
    />
  </ContentSection>

  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-out'
  >
    <form
      class="form"
      on:submit|preventDefault={logIn}
    >
      <div class="Wrap Wrap--inputs u-flows">
        <div class="form__input-group--integrated">
          <label
            class="use-colour--foreground" for="email"
          >
            {translations && translations[translationKey].loggedOut.emailLabel}
          </label>
          <input
            bind:value={email}
            type="email"
            name="email"
            id="email"
            required
            class="use-colour--foreground"
          >
        </div>

        <div class="form__input-group--integrated">
          <label
            class="use-colour--foreground" for="password"
          >
            {translations && translations[translationKey].loggedOut.passwordLabel}
          </label>
          {#if passwordVisible }
          <input
            bind:value={password}
            type="text"
            name="password"
            id="password"
            required
            class="use-colour--foreground"
          >
          {:else}
          <input
            bind:value={password}
            type="password"
            name="password"
            id="password"
            required
            class="use-colour--foreground"
          >
          {/if}

          <div class="form__input-group__icon use-colour--foreground">
            <button
              class="form__input-group__icon-content"
              on:click|preventDefault={togglePasswordVisibility}
              type="button"
            >
              <div class="SvgWrap">
                <div class="SvgWrap__padder" style="padding-bottom: 100%;"></div>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32" role="img" aria-label="GLADD">
                  {#if passwordVisible }
                  <path d="M16 6c-6.979 0-13.028 4.064-16 10 2.972 5.936 9.021 10 16 10s13.027-4.064 16-10c-2.972-5.936-9.021-10-16-10zM23.889 11.303c1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303s-5.527-0.796-7.889-2.303c-1.88-1.199-3.473-2.805-4.67-4.697 1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 4.418 3.582 8 8 8s8-3.582 8-8c0-0.962-0.17-1.883-0.482-2.737 0.124 0.074 0.248 0.15 0.371 0.228v0zM16 13c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                  {:else}
                  <path d="M29.561 0.439c-0.586-0.586-1.535-0.586-2.121 0l-6.318 6.318c-1.623-0.492-3.342-0.757-5.122-0.757-6.979 0-13.028 4.064-16 10 1.285 2.566 3.145 4.782 5.407 6.472l-4.968 4.968c-0.586 0.586-0.586 1.535 0 2.121 0.293 0.293 0.677 0.439 1.061 0.439s0.768-0.146 1.061-0.439l27-27c0.586-0.586 0.586-1.536 0-2.121zM13 10c1.32 0 2.44 0.853 2.841 2.037l-3.804 3.804c-1.184-0.401-2.037-1.521-2.037-2.841 0-1.657 1.343-3 3-3zM3.441 16c1.197-1.891 2.79-3.498 4.67-4.697 0.122-0.078 0.246-0.154 0.371-0.228-0.311 0.854-0.482 1.776-0.482 2.737 0 1.715 0.54 3.304 1.459 4.607l-1.904 1.904c-1.639-1.151-3.038-2.621-4.114-4.323z"></path>
                  <path d="M24 13.813c0-0.849-0.133-1.667-0.378-2.434l-10.056 10.056c0.768 0.245 1.586 0.378 2.435 0.378 4.418 0 8-3.582 8-8z"></path>
                  <path d="M25.938 9.062l-2.168 2.168c0.040 0.025 0.079 0.049 0.118 0.074 1.88 1.199 3.473 2.805 4.67 4.697-1.197 1.891-2.79 3.498-4.67 4.697-2.362 1.507-5.090 2.303-7.889 2.303-1.208 0-2.403-0.149-3.561-0.439l-2.403 2.403c1.866 0.671 3.873 1.036 5.964 1.036 6.978 0 13.027-4.064 16-10-1.407-2.81-3.504-5.2-6.062-6.938z"></path>
                  {/if}
                </svg>
              </div>
            </button>
          </div>
        </div>

        <div class="MemberProfile__cta-and-forgotten">
          <Cta
            content={translations && translations[translationKey].loggedOut.loginButton}
            click={logIn}
            submit={true}
            loading={loadingState.indexOf(logInLoadingKey) !== -1}
          />

          <div class="MemberProfile__forgotten-password">
            <button
              title={translations && translations[translationKey].loggedOut.forgottenPasswordHover}
              on:click|preventDefault={forgotten}
              type="button"
              class="use-colour--foreground"
            >
              {translations && translations[translationKey].loggedOut.forgottenPassword}
            </button>
          </div>
        </div>
      </div>
    </form> <!-- .form -->

    <AuthErrorsNotificationList />
  </ContentSection>

  <ContentSection
    className='MemberProfile__content MemberProfile__content--logged-out'
  >
    <Header
      header={translations && translations[translationKey].loggedOut.postFormLarge}
      preHeader={translations && translations[translationKey].loggedOut.postFormSmall}
    />

    <RichText
      content={translations && translations[translationKey].loggedOut.postFormMessage}
    />
  </ContentSection>
{/if}
