<script>
  import { fade } from 'svelte/transition';

  // svelte components
  import RichText from './RichText.svelte';
  import Header from './Header.svelte';

  export let notifications = [];
  export let removeById;
  export let align = 'left';
</script>

{#if notifications.length > 0}
  <div class="NotificationList">
    <ul class="u-flows">
      {#each notifications as notification (notification.id)}
        <li
          class="NotificationList__notification"
          in:fade
        >
          <button
            class="u-flows use-colour--foreground"
            on:click={() => { removeById(notification.id); }}
          >
            {#if notification.header || notification.preHeader}
              <Header
                header={notification.header}
                preHeader={notification.preHeader}
                align={align}
              />
            {/if}

            <RichText
              content={notification.message}
              align={align}
            />

            <div class="NotificationList__notification__close use-background--foreground">
              <div class="SvgWrap">
                <div
                  class="SvgWrap__padder"
                  style="padding-bottom: 100%;"
                ></div>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="16px"
                  height="16px"
                  viewBox="0 0 165 165"
                >
                  <path d="M126 164l-43-43-43 44-40-40 44-43L1 39 39 1l42 43 44-44 40 40-44 44 43 43-38 37z"/>
                  <image src="/images/fallback/close--opt.png" xlink:href="" />
                </svg>
              </div>
            </div>
          </button>
        </li>
      {/each}
    </ul>
  </div>
{/if}
