/** Temporary colour mode store
 *  ------------------------------------------------------------------------------------------------
**/
import { writable } from 'svelte/store';

const store = writable(
  parseInt(localStorage.getItem('store--colour-mode') || -1, 10),
);
store.subscribe((state) => localStorage.setItem('store--colour-mode', state));

const reset = () => store.set(-1);
const set = (val) => store.set(val);
const increment = () => store.update((state) => ((state + 1) < 2 ? state + 1 : 0));

// we export our own object
// which allows us to control store business logic away from our components
// it also means we can exclude `set` and `update` methods - we never want them called directly
const colourStore = {
  subscribe: store.subscribe,
  reset,
  increment,
  set,
};
export default colourStore;
